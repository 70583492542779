import axios from "axios";

export const createAgent = async (values) => {
    console.log("values", values);
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/create/agent`,
    // `${process.env.BASE_URL}/agent/create`,
    values
  );
}

export const listAgentByParams = async (params) => {
    return await axios.post(
        `${process.env.BASE_URL}/api/auth/list/agent/params`,
        params
    );
}



export const removeAgent = async (_id) => {
    return await axios.delete(
        `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove/agent/${_id}`
    );
}

export const listAgent = async () => {
    return await axios.get(
        `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/agent`
    );
}

export const updateAgent = async (_id, values) => {
    return await axios.put(
        `${process.env.REACT_APP_BASE_URL}/auth/update/agent/${_id}`,
        values
    );
}

export const getAgent = async (_id) => {
    return await axios.get(
        `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/agent/${_id}`
    );
}

export const deleteAgent = async (_id) => {
    return await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/auth/delete/agent/${_id}`
    );
}

export const getAgentsForCertificate = async (_id) =>{
    return await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/get/agentsByCertificate/${_id}`,
    );
}