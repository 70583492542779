import React, {useState, useEffect} from "react";
import moment from "moment-timezone";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select';

import "./TypeOfCertificate.css"


import CreatableSelect from 'react-select/creatable';
import {
    addCertificateMaster,
    deleteCertificateMaster,
    updateCategoryToCertificate
} from "../../functions/CertificatesV/Certificates";
import {saveAs} from 'file-saver';
import {toast, ToastContainer} from "react-toastify";

const TypeOfCertificate = () => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [toggleSwitch, setToggleSwitch] = useState(true)
    const [searchOptionList, setSearchOptionList] = useState([]);
    const [searchOption, setSearchOption] = useState(null);

    const fetchAllCategories = async () => {
        console.log("here")
        await axios
            .get(`${process.env.REACT_APP_BASE_URL}/list/categories`)
            .then((response) => {
                if (response) {
                    console.log('AllCategories:', response);
                    // setSearchOption(response.data);
                    const fetchedCertificates = response.map(cert => ({
                        value: cert.key,
                        label: cert.categoryName,
                        key: cert
                    }));
                    setSearchOptionList(fetchedCertificates);
                }
            })
            .catch(e => toast.error("Error fetching categories"));
    };
    const fetchCertificatesByCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        console.log(searchOption)
        // if(searchOption.key._id ){
        await axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/${searchOption ? searchOption.key._id : ""}`,
            )
            .then((response) => {
                if (response) {
                    console.log('Rows:', response);
                    setLoading(false);
                    setData(response);
                    setTotalRows(response.count);
                } else if (response.length === 0) {
                    setData([]);
                }
            }).catch((error) => {
                setData([])
                // toast.error("Error fetching certificates");
            });

        setLoading(false);
    };

    const handleSelectChange = (searchOption) => {
        // if(searchOption!==null)
        {
            setSearchOption(searchOption);
            setQuery(searchOption ? searchOption.label : '');
        }
    };


    const handleChangeOfSelect = (newValue) => {
        setSelectedOptions(newValue);
        console.log('newValue:', selectedOptions);
    };


    const [certificateName, setcertificateName] = useState("");
    const [industries, setindustries] = useState([])
    const [isActive, setisActive] = useState(true);
    const [data, setData] = useState([]);

    const [loadingOption, setLoadingOption] = useState(false);

    const [userId, setuserId] = useState(localStorage.getItem("AdminUser"));

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [filter, setFilter] = useState(true);
    const [filterV, setFilterV] = useState(true);

    const [showForm, setShowForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);

    const [query, setQuery] = useState("");

    const [_id, set_Id] = useState("");
    const [remove_id, setRemove_id] = useState("");

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log("no errors");
        }
    }, [formErrors, isSubmit]);


    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
    };

    // const handleTog_edit = async (row) => {
    //     console.log("row", row);
    //     // console.log("certificateCategory: ", row.certificateCategory);
    //     setindustries(row.certificateCategory);
    //     setSelectedOptions(row.certificateCategory.map(category => ({ value: category._id, label: category.categoryName })))
    //     setIsSubmit(false);
    //     setcertificateName(row.certificateName);
    //     setUpdateForm(true);
    //     // fetchCertificates()
    //     set_Id(row._id);
    //     setisActive(row.isActive);
    // };
    const handleTog_edit = (row) => {
        console.log("row", row.certificateCategory.categoryName);
        // setindustries(row.certificateCategory);
        setSelectedOptions(row.certificateCategory.map(category => ({
            value: category._id,
            label: category.categoryName
        })))
        setIsSubmit(false);
        setcertificateName(row.certificateName);
        setUpdateForm(true);
        set_Id(row._id);
        setisActive(row.isActive);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setFormErrors({});
        let errors = validate();
        setFormErrors(errors);
        setIsSubmit(true);

        if (Object.keys(errors).length === 0) {
            setLoadingOption(true);
            const transformedCategories = selectedOptions.map(option => option.key);

            const formData = {
                certificateName: certificateName,
                certificateCategory: transformedCategories,
                isActive: isActive
            };
            // console.log("data", formData)
            addCertificateMaster(formData)
                .then((res) => {
                    setcertificateName("");
                    setindustries([]);
                    setShowForm(false);
                    setLoadingOption(false);
                    setIsSubmit(false);
                    setFormErrors({});
                    setSearchOption(null);
                    setSelectedOptions([])
                    if (!toggleSwitch) {
                        fetchCertificatesByCategories();
                    } else {
                        fetchCategories();
                    }
                    toast.success("Certificate added successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        deleteCertificateMaster(remove_id)
            .then((res) => {
                setmodal_delete(!modal_delete);
                if (!toggleSwitch) {
                    fetchCertificatesByCategories();
                } else fetchCategories();
                toast.success("Certificate removed successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        let erros = validate();
        setFormErrors(erros);
        setIsSubmit(true);

        if (Object.keys(erros).length === 0) {
            setLoadingOption(true);
            console.log("s", selectedOptions);
            const transformedCategories = selectedOptions.map(option => option.value);

            const formData = {
                certificateName: certificateName,
                certificateCategory: transformedCategories,
                isActive: isActive
            }
            console.log("tt", transformedCategories);
            updateCategoryToCertificate(_id, formData)
                .then((res) => {
                    setcertificateName("");
                    setSelectedOptions([]);
                    setindustries([]);
                    setShowForm(false);
                    setUpdateForm(false);
                    setLoadingOption(false);
                    setIsSubmit(false);
                    setFormErrors({});
                    if (!toggleSwitch) {
                        fetchCertificatesByCategories();
                    } else fetchCategories();
                    toast.success("Certificate updated successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const [errBT, setErrBT] = useState(false);
    const [errBD, setErrBD] = useState(false);
    const [errBTD, setErrBTD] = useState(false);
    const [errBI, setErrBI] = useState(false);

    const validate = () => {
        const errors = {};
        if (certificateName === "") {
            errors.certificateName = "agent name is required!";
            setErrBT(true);
        }
        return errors;
    };
    const [errSN, setErrSN] = useState(false);
    const validClassStateName =
        errSN && isSubmit ? "form-control is-invalid" : "form-control";

    // const validClassBT =
    //     errBT && isSubmit ? "form-control" : "form-control";

    const validClassBD =
        errBD && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassBTD =
        errBTD && isSubmit ? "form-control is-invalid" : "form-control";

    const validClassBI =
        errBI && isSubmit ? "form-control is-invalid" : "form-control";

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    useEffect(() => {
        if (!toggleSwitch) {
            fetchCertificatesByCategories();
        } else fetchCategories();
    }, [pageNo, perPage, column, sortDirection, query, filter, filterV, toggleSwitch]);

    const fetchCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/list-by-params/certificateMaster`,
                {
                    skip: skip,
                    per_page: perPage,
                    sorton: column,
                    sortdir: sortDirection,
                    match: query,
                    isActive: filter,
                }
            )
            .then((response) => {
                if (response) {
                    setLoading(false);
                    setData(response.data);
                    // console.log("response data: ", response.total)
                    // console.log("data: ", data.length)
                    setTotalRows(response.total);
                } else if (response.length === 0) {
                    setData([]);
                }
            })
            .catch((error) => {
                setData([]);
                toast.error("Error fetching certificates");
            })
        setLoading(false);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
    };
    const handleFilter = (e) => {
        setFilter(e.target.checked);
    };
    const handleFilterV = (e) => {
        setFilterV(e.target.checked);
    };

    const handleAddCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        // setCheckImagePhoto(false);
        setShowForm(false);
        setUpdateForm(false);
        setcertificateName("");
        setindustries([]);
        setSelectedOptions([]);
        //setisVerified(false);
    };

    const handleUpdateCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        setUpdateForm(false);
        setShowForm(false);
        setSelectedOptions([]);
        setindustries([]);
        // setCheckImagePhoto(false);
        setcertificateName("");
        ////////////setmobileNumber("");
        // setemail("");
        ////setpassword("");
        setindustries([]);
        //setisVerified(false);
        setuserId("");
    };

    // const [industries, setProvidingCertificates] = useState([]);
    const validClassBT = ""; // Add your validation class logic here

    const col = [
        {
            name: "Certificate Type",
            cell: (row) => row.certificateName,
            sortable: true,
            sortField: "certificateName",
            minWidth: "150px",
        },
        {
            name: "Categories",
            cell: (row) => (
                <div style={{maxWidth: "200px", overflowX: "auto"}}>
                    {row.certificateCategory.length > 0 && (
                        row.certificateCategory.map((industry) => (
                            <div key={industry}>{industry.categoryName}</div>
                        ))
                    )}
                </div>
            ),
            sortable: true,
            sortField: "industries",
            minWidth: "150px",
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <React.Fragment>
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button
                                    className="btn btn-sm btn-success edit-item-btn "
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                    onClick={() => {
                                        handleTog_edit(row)
                                        // console.log(row)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>

                            <div className="remove">
                                <button
                                    className="btn btn-sm btn-danger remove-item-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteRecordModal"
                                    onClick={() => tog_delete(row._id)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            },
            sortable: false,
            minWidth: "180px",
        },
    ];

    const filteredColumns = toggleSwitch ? col : col.filter(column => column.name !== "Categories");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#e9ecef' : '#fff', // Change option background color on focus
            color: state.isSelected ? '#495057' : '#000', // Change option text color on select
            '&:active': {
                // backgroundColor: state.isSelected ? '#000' : '#e9ecef' // Change background color when active
            }
        }),
    };

    // SearchableDropDown
    const [options, setOptions] = useState([]);
    const fetchCertificates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/categories`);
            const fetchedCertificates = response.map(cert => ({
                value: cert._id,
                label: cert.categoryName,
                key: cert
            }));
            setOptions(fetchedCertificates);
            // console.log('fetchedCertificates:', fetchedCertificates);
        } catch (error) {
            console.error('Error fetching certificates:', error);
            toast.error('Error fetching certificates:');
        }
    };
    useEffect(() => {

        fetchCertificates();
    }, []);


    const handleChange = (selected) => {
        setindustries(selected ? selected.map(option => option.value) : []);
    };

    const handleDownloadExcel = async () => {
        try {
          let skip = (pageNo - 1) * perPage;
          if (skip < 0) {
            skip = 0;
          }
      
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/auth/sheet/certificateMaster`,
            {
              skip: skip,
              per_page: perPage,
              sorton: column,
              sortdir: sortDirection,
              match: query,
              isVerified: filterV,
              isActive: filter,
            },
            {
              responseType: 'blob', // Important to handle binary data
            }
          );
      
          console.log("excel response", response);
      
          // Create a new Blob object using the response data of the file
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
      
          // Save the file using FileSaver
          saveAs(blob, 'report.xlsx');
        } catch (error) {
          console.error("Error downloading the file:", error);
            toast.error("Error downloading the file");
        }
      };
      
    document.title = "Certificate Master | EHS";


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb maintitle="Master" title="CERTIFICATE MASTER" pageTitle="Master"/>

                    <Row>
                        <Col lg={12}>
                            <Card>


                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        {/* Certificate Master and Active checkbox */}
                                        <Col lg={4} md={6} sm={12} className="d-flex align-items-center">
                                            <h2 className="card-title mb-0 fs-4 mt-2">CERTIFICATE MASTER</h2>
                                        </Col>
                                        <Col lg={2} md={6} sm={12} className="d-flex align-items-center">
                                            <div style={{ display: showForm || updateForm ? "none" : "" }}>
                                                <div className="text-end mt-1">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="filter"
                                                        value={filter}
                                                        defaultChecked={true}
                                                        onChange={handleFilter}
                                                    />
                                                    <Label className="form-check-label ms-2">Active</Label>
                                                </div>
                                            </div>
                                        </Col>

                                        {/* Download, Add buttons and toggle switch */}
                                        <Col lg={6} md={12} sm={12} className="d-flex justify-content-sm-end">
                                            <div style={{ display: showForm || updateForm ? "none" : "" }} className="d-flex justify-content-end w-100">
                                                <Button color="success" className="add-btn me-1" onClick={handleDownloadExcel} style={{display: showForm || updateForm ? "none" : "" }}>
                                                <i className="ri-download-2-fill align-bottom me-1"></i>{" "}
                                                    Download
                                                </Button>
                                                <Button
                                                    color="success" 
                                                    className="add-btn me-1"
                                                    onClick={() => {
                                                        setShowForm(!showForm);
                                                        setuserId("");
                                                        setcertificateName("");
                                                        setindustries([]);
                                                    }}
                                                    style={{display: showForm || updateForm ? "none" : "" }}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    Add
                                                </Button> 
                                            </div>

                                            <div className="d-flex align-items-center mt-2 mt-lg-0">
                                                <p style={{ display: showForm || updateForm ? "none" : "" }}>Category</p>
                                                <div className="toggle-switch ms-2" style={{ display: showForm || updateForm ? "none" : "" }}>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={true}
                                                            onChange={(e) => {
                                                                setToggleSwitch(e.target.checked);
                                                                setSearchOption(null);
                                                                setQuery("");
                                                            }}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <p style={{ display: showForm || updateForm ? "none" : "" ,marginLeft:"6px"}}>Name</p>

                                                <div
                                                    className="search-box ms-2"
                                                    style={{
                                                        width: "240px",
                                                        display: showForm || updateForm || toggleSwitch ? "none" : "",
                                                    }}
                                                >
                                                    <Select
                                                        value={searchOption}
                                                        onChange={handleSelectChange}
                                                        options={searchOptionList}
                                                        placeholder="No Search..."
                                                        styles={customStyles}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>

                                            <div style={{ display: showForm || updateForm ? "" : "none" }} className="w-100">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="text-end">
                                                            <button
                                                                className="btn bg-success text-light mb-3"
                                                                onClick={() => {
                                                                    setuserId("");
                                                                    setShowForm(false);
                                                                    setUpdateForm(false);
                                                                    setcertificateName("");
                                                                    setindustries([]);
                                                                }}
                                                            >
                                                                <i className="ri-list-check align-bottom me-1"></i> List
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="search-box ms-2" style={{ display: toggleSwitch&&!(showForm||updateForm) ?"": "none", width: "36vw" }}>
                                                <input
                                                    className="form-control search"
                                                    placeholder="Search..."
                                                    onChange={(e) => setQuery(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <div
                                    style={{
                                        display: showForm && !updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    {/* <PreviewCardHeader title="Billing Product Form" /> */}
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"certificateName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Certificate Name"
                                                                                required
                                                                                name="certificateName"
                                                                                value={certificateName}
                                                                                onChange={(e) => {
                                                                                    setcertificateName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Certificate Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.certificateName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div>
                                                                            <h6>Selected Industry</h6>
                                                                            <CreatableSelect
                                                                                isMulti
                                                                                value={selectedOptions}
                                                                                onChange={handleChangeOfSelect}
                                                                                options={options}
                                                                                placeholder="Select"
                                                                            />
                                                                            <div>
                                                                                <ul>
                                                                                    {industries.map(id => {
                                                                                        const certificate = options.find(option => option.value === id);
                                                                                        return <li
                                                                                            key={id}>{certificate ? certificate.label : id}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsActive" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>

                                                                        </Col>
                                                                    </div>

                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                                                                <span className="sr-only">
                                                                                  Loading...
                                                                                </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div
                                                                            className="hstack gap-2 justify-content-end">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success  m-1"
                                                                                id="add-btn"
                                                                                onClick={handleClick}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleAddCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>{" "}
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* UPDATE FORM  */
                                }
                                <div
                                    style={{
                                        display: !showForm && updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"certificateName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Certificate Name"
                                                                                required
                                                                                name="certificateName"
                                                                                value={certificateName}
                                                                                onChange={(e) => {
                                                                                    setcertificateName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Certificate Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.certificateName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div>
                                                                            <h6>Selected Industry</h6>
                                                                            <CreatableSelect
                                                                                isMulti
                                                                                value={selectedOptions}
                                                                                onChange={handleChangeOfSelect}
                                                                                options={options}
                                                                                placeholder="Select or add languages"
                                                                            />
                                                                            <div>
                                                                                <ul>
                                                                                    {industries.map(id => {
                                                                                        const certificate = options.find(option => option.value === id);
                                                                                        return <li
                                                                                            key={id}>{certificate ? certificate.label : id}</li>;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsActive" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>

                                                                        </Col>
                                                                    </div>

                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div className="text-end">
                                                                            <button
                                                                                type="submit"
                                                                                className=" btn btn-success m-1"
                                                                                id="add-btn"
                                                                                onClick={handleUpdate}
                                                                            >
                                                                                Update
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleUpdateCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* list */
                                }
                                <div
                                    style={{
                                        display: showForm || updateForm ? "none" : "block",
                                    }}
                                >
                                    <CardBody>
                                        <div>
                                            <div className="table-responsive table-card mt-1 mb-1 text-right">
                                                <DataTable
                                                    columns={filteredColumns}
                                                    data={data}
                                                    progressPending={loading}
                                                    sortServer
                                                    onSort={(column, sortDirection, sortedRows) => {
                                                        handleSort(column, sortDirection);
                                                    }}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationRowsPerPageOptions={[
                                                        10,
                                                        50,
                                                        100,
                                                        totalRows,
                                                    ]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*Remove Modal*/
            }
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                    setuserId("");
                    setcertificateName("");
                    setindustries([]);
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(!modal_delete);
                    }}
                >
                    <span style={{marginRight: "210px"}}>Remove Certificate</span>
                </ModalHeader>

                <form>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{width: "100px", height: "100px"}}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-danger"
                                id="add-btn"
                                onClick={handleDelete}
                            >
                                Remove
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => setmodal_delete(false)}
                            >
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer/>
        </React.Fragment>
    )
        ;
};

export default TypeOfCertificate;
