import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    Row,
    Toast,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select';
import {saveAs} from 'file-saver';

import {
    removeBlogs,
} from "../../functions/Blogs/Blogs";

import {
    createAgent,
    deleteAgent,
    getAgent,
    listAgent,
    removeAgent,
    updateAgent,
} from "../../functions/AgentV/Agent";
import { ToastContainer, toast } from "react-toastify";

const AgentMaster = () => {

    const [agentName, setagentName] = useState("");
    const [mobileNumber, setmobileNumber] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [providingCertificates, setprovidingCertificates] = useState([])
    const [proof, setProof] = useState("");
    const [isVerified, setisVerified] = useState(false);
    const [isActive, setisActive] = useState(false);
    const [data, setData] = useState([]);

    const [loadingOption, setLoadingOption] = useState(false);

    const [likes, setlikes] = useState([]);
    const [comments, setcomments] = useState([]);
    const [userId, setuserId] = useState(localStorage.getItem("AdminUser"));

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [filter, setFilter] = useState(true);
    const [filterV, setFilterV] = useState(true);

    const [showForm, setShowForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);

    const [query, setQuery] = useState("");

    const [_id, set_Id] = useState("");
    const [remove_id, setRemove_id] = useState("");

    const [gotFromBackend, setGotFromBackend] = useState(false);

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log("no errors");
        }
    }, [formErrors, isSubmit]);


    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
    };

    const [modal_edit, setmodal_edit] = useState(false);
    const handleTog_edit = (row) => {
        // setmodal_edit(!modal_edit);
        setIsSubmit(false);
        setUpdateForm(true);
        // setSelectedFile(row.)

        setIsSubmit(false);
        set_Id(row._id);
        setagentName(row.name);
        setmobileNumber(row.mobileNumber);
        setFileURL(row.proof);
        setSelectedFile(row.proofPDFUrl);
        if(row.proofPDFUrl){
            setGotFromBackend(true);
        }
        setemail(row.email);
        setpassword(row.password);
        setprovidingCertificates(row.providingCertificates);
        setisVerified(row.isVerified);
        setisActive(row.isActive);
        setProof(row.proof);
        setFileURL(row.proof);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileURL, setFileURL] = useState("");
  
    // Handle file selection
    // const handleFileChange = (e) => {
    //   const file = e.target.files[0];
    //   if (file) {
    //     setGotFromBackend(false);
    //     setSelectedFile(file);
    //     console.log(file);
    //     setFileURL(URL.createObjectURL(file));
    //   }
    // };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      
        if (file) {
          if (file.size > maxSize) {
            toast.error("File size exceeds 5 MB. Please select a smaller file.");
            return;
          }
          setGotFromBackend(false);
          setSelectedFile(file);
          console.log(file);
          setFileURL(URL.createObjectURL(file));
        }
    };

    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = selectedFile;
    //     link.setAttribute('download', selectedFile.name);
    //     // link.setAttribute('download', selectedFile.split('/').pop()); 
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     // window.open(selectedFile.name, "_blank");
    //   };
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = selectedFile;
        link.target = '_blank'; // Open the link in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleDownloadSub = () => {
        if (selectedFile) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(selectedFile);
            link.download = selectedFile.name;
            link.target = '_blank'; // Open the link in a new tab
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href); // Clean up the object URL
        }
    };

    const handleClick = (e) => {
        
        e.preventDefault();
        setFormErrors({});
        let errors = validate();
        setFormErrors(errors);
        setIsSubmit(true);
    
        if (Object.keys(errors).length === 0) {
            setLoadingOption(true);

            const formData = new FormData();
            formData.append("name", agentName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            formData.append("password", password);
            formData.append("providingCertificates", providingCertificates);
            formData.append("isVerified", isVerified);
            formData.append("isActive", isActive);
            formData.append("agentProof", selectedFile);
            
    
            createAgent(formData)
                .then((res) => {
                    // console.log(res);
                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setpassword("");
                    setProof("");
                    setprovidingCertificates([]);
                    setisVerified(false);
                    setisActive(false);
                    setShowForm(false);
                    setLoadingOption(false);
                    setIsSubmit(false);
                    setFormErrors({});
                    fetchCategories();
                    toast.success("Agent Added Successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        deleteAgent(remove_id)
            .then((res) => {
                setmodal_delete(!modal_delete);
                fetchCategories();
                toast.success("Agent Removed Successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = (e) => {
        // console.log("Providing cert",providingCertificates);
        // console.log("type",typeof providingCertificates[0]);
        e.preventDefault();
        let erros = validate();
        setFormErrors(erros);
        setIsSubmit(true);
console.log("Providing cert",providingCertificates);
        if (Object.keys(erros).length === 0) {
            setLoadingOption(true);

            const formData = new FormData();
            formData.append("name", agentName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            formData.append("password", password);
            formData.append("providingCertificates", providingCertificates);
            formData.append("isVerified", isVerified);
            formData.append("isActive", isActive);
            formData.append("agentProof", selectedFile);
            formData.append("proof", proof);

            updateAgent(_id, formData)
                .then((res) => {
                    // setmodal_edit(!modal_edit);
                    // setPhotoAdd("");
                    setUpdateForm(false);
                    setLoadingOption(false);

                    // setCheckImagePhoto(false);
                    setuserId("");
                    // setViews(0);
                    // setblogImage("");
                    setisActive(false);
                    setisVerified(false);
                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setProof("");
                    setpassword("");
                    setprovidingCertificates([]);
                    fetchCategories();
                    toast.success("Agent Updated Successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const [errBT, setErrBT] = useState(false);
    const [errBD, setErrBD] = useState(false);
    const [errBTD, setErrBTD] = useState(false);
    const [errBI, setErrBI] = useState(false);

    const validate = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (agentName === "") {
            errors.agentName = "Authority name is required!";
            setErrBT(true);
        }
        if (mobileNumber === "" || mobileNumber.length < 10) {
            errors.mobileNumber = "mobile number is invalid!";
            setErrBT(true);
        }

        if (email === "" || !emailRegex.test(email)) {
            errors.email = "valid email is required!";
            setErrBD(true);
        }
        if (password === "") {
            errors.password = "password is required!";
            setErrBI(true);
        }
        // if (providingCertificates !== "") {
        //     setErrBD(false);
        // }
        return errors;
    };
    const [errSN, setErrSN] = useState(false);
    const validClassStateName =
        errSN && isSubmit ? "form-control is-invalid" : "form-control";

    // const validClassBT =
    //     errBT && isSubmit ? "form-control" : "form-control";

    const validClassBD =
        errBD && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassBTD =
        errBTD && isSubmit ? "form-control is-invalid" : "form-control";

    const validClassBI =
        errBI && isSubmit ? "form-control is-invalid" : "form-control";

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [pageNo, perPage, column, sortDirection, query, filter, filterV]);

    const fetchCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/auth/list-by-params/agents`,
                {
                    skip: skip,
                    per_page: perPage,
                    sorton: column,
                    sortdir: sortDirection,
                    match: query,
                    isVerified: filterV,
                    isActive: filter,
                }
            )
            .then((response) => {
                if (response) {
                    // let res = response[0];
                    setLoading(false);
                    console.log(response);
                    setData(response.data);
                    setTotalRows(response.total);
                } else if (response.total === 0) {
                    setData([]);
                }
                // console.log(res);
            });

        setLoading(false);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
    };
    const handleFilter = (e) => {
        setFilter(e.target.checked);
    };
    const handleFilterV = (e) => {
        setFilterV(e.target.checked);
    };

    const handleAddCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        // setCheckImagePhoto(false);
        setShowForm(false);
        setUpdateForm(false);
        setagentName("");
        setProof("");
        setmobileNumber("");
        setemail("");
        setSelectedFile(null);
        setFileURL("");
        setpassword("");
        setprovidingCertificates([]);
        setisVerified(false);
        setisActive(false);
    };

    const handleUpdateCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        setUpdateForm(false);
        setShowForm(false);
        // setCheckImagePhoto(false);
        setagentName("");
        setmobileNumber("");
        setemail("");
        setProof("");
        setpassword("");
        setprovidingCertificates([]);
        setisVerified(false);
        setisActive(false);
        setuserId("");
    };

    // const [providingCertificates, setProvidingCertificates] = useState([]);
    const validClassBT = ""; // Add your validation class logic here

    const col = [
        {
            name: "Name",
            cell: (row) => row.name,
            sortable: true,
            sortField: "agentName",
            minWidth: "150px",
        },
        {
            name: "Mobile Number",
            cell: (row) => row.mobileNumber,
            sortable: true,
            sortField: "mobileNumber",
            minWidth: "150px",
        },
        {
            name: "Email",
            cell: (row) => row.email,
            sortable: true,
            sortField: "email",
            minWidth: "150px",
        },
        {
            name: "Password",
            cell: (row) => row.password,
            sortable: true,
            sortField: "password",
            minWidth: "150px",
        },
        // {
        //     name: "Providing Certificates",
        //     cell: (row) => row.providingCertificates.certificateName,
        //     sortable: true,
        //     sortField: "providingCertificates",
        //     minWidth: "150px",
        // },

        {
            name: "Varified",
            selector: (row) => {
                return <p>{row.isVerified ? "Verified" : "Not Verified"}</p>;
            },
            sortable: false,
            sortField: "Status",
        },
        {
            name: "Active",
            selector: (row) => {
                return <p>{row.isActive ? "Active" : "InActive"}</p>;
            },
            sortable: false,
            sortField: "Status",
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <React.Fragment>
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button
                                    className="btn btn-sm btn-success edit-item-btn "
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                    onClick={() => {
                                        handleTog_edit(row)
                                        // console.log(row)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>

                            <div className="remove">
                                <button
                                    className="btn btn-sm btn-danger remove-item-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteRecordModal"
                                    onClick={() => tog_delete(row._id)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            },
            sortable: false,
            minWidth: "180px",
        },
    ];

    // SearchableDropDown
    const [options, setOptions] = useState([]);

    useEffect(() => {

        const fetchCertificates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
                // console.log('Response data:', response);
                const fetchedCertificates = response.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
                // console.log('fetchedCertificates:', fetchedCertificates);
            } catch (error) {
                console.error('Error fetching certificates:', error);
            }
        };
    
        fetchCertificates();
    }, []);
    

    // const handleChange = (selected) => {
    //     setprovidingCertificates(selected ? selected.map(option => option.value) : null);
    // };
    const handleChange = (selected) => {
        // Convert the selected options to an array of strings
        const certificatesArray = selected ? selected.map(option => option.value) : [];
        
        // Update the state with the correctly formatted array
        setprovidingCertificates(certificatesArray);
    };
      
    // const handleChange = (selected) => {
    //     if (selected) {
    //         const objectIdArray = selected.map(option => {
    //             // Convert each string value to an ObjectId
    //             return mongoose.Types.ObjectId(option.value);
    //         });
    //         setprovidingCertificates(objectIdArray);
    //     } else {
    //         setprovidingCertificates(null);
    //     }
    // };

    const handleDownloadExcel = async () => {
        try {
            let skip = (pageNo - 1) * perPage;
            if (skip < 0) {
                skip = 0;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/auth/sheet/agent`,
                {
                    skip: skip,
                    per_page: perPage,
                    sorton: column,
                    sortdir: sortDirection,
                    match: query,
                    isVerified: filterV,
                    isActive: filter,
                },
                {
                    responseType: 'blob',  // This is crucial to handle binary data properly
                }
            );

            const blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // Save the file using FileSaver
            saveAs(blob, 'report.xlsx');
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };


    document.title = "Authority Master | EHS Certificate";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb maintitle="Authorities" title="Authorities Master" pageTitle="Authorities" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        {/* Agents Master */}
                                        <Col lg={4} md={6} sm={12}>
                                            <h2 className="card-title mb-0 fs-4 mt-2">Authorities Master</h2>
                                        </Col>

                                        {/* Verified and Active Checkboxes */}
                                        <Col lg={2} md={3} sm={6} className="d-flex align-items-center justify-content-md-end">
                                            <div style={{ display: showForm || updateForm ? "none" : "" }}>
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="filterV"
                                                    value={filterV}
                                                    defaultChecked={true}
                                                    onChange={handleFilterV}
                                                />
                                                <Label className="form-check-label ms-2">Verified</Label>
                                            </div>
                                        </Col>

                                        <Col lg={2} md={3} sm={6} className="d-flex align-items-center justify-content-md-end">
                                            <div style={{ display: showForm || updateForm ? "none" : "" }}>
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="filter"
                                                    value={filter}
                                                    defaultChecked={true}
                                                    onChange={handleFilter}
                                                />
                                                <Label className="form-check-label ms-2">Active</Label>
                                            </div>
                                        </Col>

                                        {/* Buttons and Search Box */}
                                        <Col lg={4} md={12} sm={12}>
                                            <div className="d-flex flex-column flex-md-row justify-content-sm-end">
                                                {/* Download and Add Buttons */}
                                                <div className="d-flex mb-2 mb-md-0">
                                                    <Button
                                                        color={"success"}
                                                        className="add-btn me-1"
                                                        onClick={handleDownloadExcel}
                                                        style={{ display: showForm || updateForm ? "none" : "" }}
                                                    >
                                                        <i className="ri-download-2-line align-bottom me-1"></i>
                                                        Download
                                                    </Button>
                                                    <Button
                                                        color="success"
                                                        className="add-btn me-1"
                                                        onClick={() => {
                                                            setShowForm(!showForm);
                                                            setlikes([]);
                                                            setcomments([]);
                                                            setuserId("");
                                                            setisActive(false);
                                                            setisVerified(false);
                                                            setagentName("");
                                                            setmobileNumber("");
                                                            setemail("");
                                                            setprovidingCertificates([]);
                                                            setpassword("");
                                                            setProof("");
                                                            setFileURL("");
                                                            setSelectedFile(null);
                                                        }}
                                                        style={{ display: showForm || updateForm ? "none" : "" }}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Add
                                                    </Button>
                                                </div>

                                                {/* Search Box */}
                                                <div className="search-box ms-2" style={{ display: showForm || updateForm ? "none" : "" }}>
                                                    <input
                                                        className="form-control search"
                                                        placeholder="Search..."
                                                        onChange={(e) => setQuery(e.target.value)}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {/* ADD FORM  */}
                                <div
                                    style={{
                                        display: showForm && !updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    {/* <PreviewCardHeader title="Billing Product Form" /> */}
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"agentName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Authority Name"
                                                                                required
                                                                                name="agentName"
                                                                                value={agentName}
                                                                                onChange={(e) => {
                                                                                    setagentName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Authority Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.agentName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"mobileNumber" + _id}
                                                                                type="number"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Mobile Number"
                                                                                required
                                                                                name="mobileNumber"
                                                                                value={mobileNumber}
                                                                                onChange={(e) => {
                                                                                    setmobileNumber(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Mobile Number{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.mobileNumber}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"email" + _id}
                                                                                type="email"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Email"
                                                                                required
                                                                                name="email"
                                                                                value={email}
                                                                                onChange={(e) => {
                                                                                    setemail(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Email{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.email}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"password" + _id}
                                                                                type="password"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Password"
                                                                                required
                                                                                name="password"
                                                                                value={password}
                                                                                onChange={(e) => {
                                                                                    setpassword(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Password{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.password}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
    <Col lg={6}>
        <div>
            <h6>Select Certificates:</h6>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => providingCertificates.includes(option.value))}
                onChange={handleChange}
            />
            <div>
                <ul>
                    {providingCertificates.map(id => {
                        const certificate = options.find(option => option.value === id);
                        return <li key={id}>{certificate ? certificate.label : id}</li>;
                    })}
                </ul>
            </div>
        </div>
    </Col>
    <Col lg={6}>
        <label>
          Authority Proof (should be in pdf, jpg, jpeg, or png format, max size: 5mb)
        </label>
        <Input
          key={"agentProof" + _id}
        //   className={validClassBI}
          type="file"
          name="agentProof"
          accept=".pdf, .jpg, .jpeg, .png"
          onChange={handleFileChange}
        />
        {selectedFile && (
          <div className="m-2">
            <p>Selected File: {selectedFile.name}</p>
            <Button color="primary" onClick={handleDownloadSub}>
              Download File
            </Button>
          </div>
        )}
      </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsVerified_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isVerified"
                                                                                    value={isVerified}
                                                                                    onChange={(e) => {
                                                                                        setisVerified(e.target.checked);
                                                                                    }}
                                                                                    checked={isVerified}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Verified
                                                                                </Label>
                                                                            </div>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"isActive_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>
                                                                        </Col>
                                                                    </div>

                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                                                                <span className="sr-only">
                                                                                  Loading...
                                                                                </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div className="hstack gap-2 justify-content-end">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success  m-1"
                                                                                id="add-btn"
                                                                                onClick={handleClick}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleAddCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>{" "}
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* UPDATE FORM  */}
                                <div
                                    style={{
                                        display: !showForm && updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    
                                                                <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"agentName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Authority Name"
                                                                                required
                                                                                name="agentName"
                                                                                value={agentName}
                                                                                onChange={(e) => {
                                                                                    setagentName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Authority Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.agentName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"mobileNumber" + _id}
                                                                                type="number"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Mobile Number"
                                                                                required
                                                                                name="mobileNumber"
                                                                                value={mobileNumber}
                                                                                onChange={(e) => {
                                                                                    setmobileNumber(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Mobile Number{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.mobileNumber}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"email" + _id}
                                                                                type="email"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Email"
                                                                                required
                                                                                name="email"
                                                                                value={email}
                                                                                onChange={(e) => {
                                                                                    setemail(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Email{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.email}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"password" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Password"
                                                                                required
                                                                                name="password"
                                                                                value={password}
                                                                                onChange={(e) => {
                                                                                    setpassword(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Password{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.password}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
    <Col lg={6}>
    <div>
            <h6>Select Certificates:</h6>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => providingCertificates.includes(option.value))}
                onChange={handleChange}
            />
            <div>
                <ul>
                    {providingCertificates.map(id => {
                        const certificate = options.find(option => option.value === id);
                        return <li key={id}>{certificate ? certificate.label : id}</li>;
                    })}
                </ul>
            </div>
        </div>
    </Col>
    
    <Col lg={6}>
        <label>
          Authority Proof(should be in pdf, jpg, jpeg, or png format, max size: 5mb)
        </label>
        <Input
          key={"agentProof" + _id}
        //   className={validClassBI}
          type="file"
          name="agentProof"
          accept=".pdf, .jpg, .jpeg, .png"
          onChange={handleFileChange}
        />
        {selectedFile && gotFromBackend && (
          <div className="m-2">
            <p>Selected File: {selectedFile.name}</p>
            <Button color="primary" onClick={handleDownload}>
              Download File
            </Button>
          </div>
        )}
      </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsVerified_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isVerified"
                                                                                    value={isVerified}
                                                                                    onChange={(e) => {
                                                                                        setisVerified(e.target.checked);
                                                                                    }}
                                                                                    checked={isVerified}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Verified
                                                                                </Label>
                                                                            </div>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"isActive_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div className="text-end">
                                                                            <button
                                                                                type="submit"
                                                                                className=" btn btn-success m-1"
                                                                                id="add-btn"
                                                                                onClick={handleUpdate}
                                                                            >
                                                                                Update
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleUpdateCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* list */}
                                <div
                                    style={{
                                        display: showForm || updateForm ? "none" : "block",
                                    }}
                                >
                                    <CardBody>
                                        <div>
                                            <div className="table-responsive table-card mt-1 mb-1 text-right">
                                                <DataTable
                                                    columns={col}
                                                    data={data}
                                                    progressPending={loading}
                                                    sortServer
                                                    onSort={(column, sortDirection, sortedRows) => {
                                                        handleSort(column, sortDirection);
                                                    }}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationRowsPerPageOptions={[
                                                        10,
                                                        50,
                                                        100,
                                                        totalRows,
                                                    ]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*Remove Modal*/}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setpassword("");
                    setProof("");
                    setprovidingCertificates([]);
                    setisActive(false);
                    setisVerified(false);
                    // setValues([]);
                    setlikes([]);
                    setcomments([]);
                    setuserId("");
                    setisVerified(false);

                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setprovidingCertificates([]);
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(!modal_delete);
                    }}
                >
                    <span style={{ marginRight: "210px" }}>Remove Authority</span>
                </ModalHeader>

                <form>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{ width: "100px", height: "100px" }}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-danger"
                                id="add-btn"
                                onClick={handleDelete}
                            >
                                Remove
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => setmodal_delete(false)}
                            >
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer />
        </React.Fragment>
    );
};

export default AgentMaster;
