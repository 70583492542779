import axios from "axios";

export const createCountry = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/create/country`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const listActiveCountry = async () => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/list/ActiveCountries`
  );
};

export const removeCountry = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/delete/country/${_id}`
  );
};

// export const removeAndUpdateCountry = async (_id) => {
//   return await axios.put(
//     `${process.env.REACT_APP_BASE_URL}/location/country/${_id}`
//   );
// };

export const updateCountry = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/update/country/${_id}`,
    values
  );
};

export const getCountry = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/country/${_id}`
  );
};

// ////////////////////////////////////////STATE//////////////////////////////

export const createState = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/create/state`,
    values
  );
};

export const removeState = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/delete/state/${_id}`
  );
};

// no use
export const listState = async (values) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/location/listStatesByCountry`,
    values
  );
};
export const listStatesByCountry = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/list/statesByCountry`,
    {
      countryID: values,
    }
  );
};

export const removeAndUpdatState = async (_id) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/state/${_id}`
  );
};

export const updateState = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/update/state/${_id}`,
    values
  );
};

export const getState = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/state/${_id}`
  );
};

// ////////////////////////////////////////CITY//////////////////////////////

export const createCity = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/create/city`,
    values
  );
};

export const listCity = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/city`
  );
};

export const listCityByState = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/list/cityByState`,
    {
      StateID: values
    }
  );
};

export const removeCity = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/delete/city/${_id}`
  );
};

export const removeAndUpdateCity = async (_id) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/city/${_id}`
  );
};

export const getCity = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/location/city/${_id}`
  );
};

export const updateCity = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/update/city/${_id}`,
    values
  );
};


export const getAddressById = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/get/addressById/${_id}`
  );
}

