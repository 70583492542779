import React, {useState, useEffect} from "react";
import moment from "moment-timezone";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {saveAs} from 'file-saver';
import {toast, ToastContainer} from "react-toastify";

import {
    listCity,
    listStatesByCountry,
    listCityByState,
    listActiveCountry,
    getAddressById,
} from "../../functions/Location/Location";

import IssueCertificateModal from "./IssueCertificateModal";

import {removeBlogs} from "../../functions/Blogs/Blogs";

import {
    createAddress,
    createCompany,
    updateAddress,
    updateCompany,
    getCompanyCertificates,
    deleteCompanyCertificate,
} from "../../functions/CustomerV/Customer";
import EditCertificateModal from "./EditCertificateModal";

const initialState = {
    CityID: "",
    CountryID: "",
    StateID: "",
    Address: "",
};

const CustomerCertificates = () => {
    const [companyName, setcompanyName] = useState("");
    const [mobileNumber, setmobileNumber] = useState("");
    const [email, setemail] = useState(""); // 3
    const [password, setpassword] = useState(""); // 4
    const [certificates, setcertificates] = useState([]); // 5
    const [isVerified, setisVerified] = useState(false); // 6
    const [isActive, setisActive] = useState(false); // 7
    const [address, setaddress] = useState(""); // 8
    const [country, setcountry] = useState(""); // 9
    const [GST, setGST] = useState(""); // 10
    const [GSTFile, setGSTFile] = useState(""); // 11
    const [companyCategory, setcompanyCategory] = useState(""); // 12
    const [certificatesWithPdfUrl, setCertificatesWithPdfUrl] = useState([]); // 13
    const [data, setData] = useState([]);
    const [CityID, setCityID] = useState("");
    const [StateID, setStateID] = useState("");
    const [CountryID, setCountryID] = useState("");
    const [companyDetails, setcompanyDetails] = useState();
    const [modal_delete2, setmodal_delete2] = useState(false);

    const [loadingOption, setLoadingOption] = useState(false);

    const [userId, setuserId] = useState(localStorage.getItem("AdminUser"));

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [filter, setFilter] = useState(true);
    const [filterV, setFilterV] = useState(true);

    const [showForm, setShowForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);

    const [query, setQuery] = useState("");

    const [_id, set_Id] = useState("");
    const [remove_id, setRemove_id] = useState("");
    const [row, setRow] = useState(null);

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            // console.log("no errors");
        }
    }, [formErrors, isSubmit]);

    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
    };

    const [modal_edit, setmodal_edit] = useState(false);
    const handleTog_edit = (row) => {
        setcompanyDetails(row);
        // setmodal_edit(!modal_edit);
        setUpdateForm(true);
        // console.log("Row", row);
        getAddressById(row.address).then((res) => {
            // console.log("Address", res);
            setCountryID(res.CountryID);
            setStateID(res.StateID);
            setCityID(res.CityID);
            setaddress(res.Address);
        });
        getCompanyCertificates(row._id).then((res) => {
            console.log("Company", res);
            setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
        });
        setGSTFile(row.GSTPDF);
        setIsSubmit(false);
        set_Id(row._id);
        setCompanyId(row._id);
        setcompanyName(row.companyName);
        setFileURL(row.GSTPDF);
        setSelectedFile(row.GSTPDFUrl);
        setaddress(row.address);
        setGST(row.GST);
        setcompanyCategory(row.companyCategory);
        setmobileNumber(row.mobileNumber);
        setemail(row.email);
        setpassword(row.password);
        setcertificates(row.certificates);
        setisVerified(row.isVerified);
        setisActive(row.isActive);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setFormErrors({});
        let errors = validate();
        setFormErrors(errors);
        setIsSubmit(true);

        if (Object.keys(errors).length === 0) {
            setLoadingOption(true);

            const addressData = {
                Address: address,
                CountryID: CountryID,
                StateID: StateID,
                CityID: CityID,
            };

            createAddress(addressData)
                .then((res) => {
                    // console.log("Address Created", res);
                    const formData = new FormData();
                    formData.append("companyName", companyName);
                    formData.append("mobileNumber", mobileNumber);
                    formData.append("address", res.data._id);
                    formData.append("GST", GST);
                    formData.append("GSTPDF", selectedFile);
                    formData.append("companyCategory", companyCategory);
                    formData.append("email", email);
                    formData.append("password", password);
                    formData.append("certificates", certificates);
                    formData.append("isVerified", isVerified);
                    formData.append("isActive", isActive);

                    console.log(formData);
                    createCompany(formData)
                        .then((res) => {
                            // console.log(res);
                            setcompanyName("");
                            setSelectedFile("");
                            setaddress("");
                            setGST("");
                            setcompanyCategory("");
                            setmobileNumber("");
                            setemail("");
                            setpassword("");
                            setcertificates([]);
                            setisVerified(false);
                            setisActive(false);
                            setShowForm(false);
                            setGSTFile("");
                            setPhotoAdd("");
                            setCheckImagePhoto(false);
                            setLoadingOption(false);
                            setIsSubmit(false);
                            setFormErrors({});
                            fetchCategories();
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log("Addrees was not Created", err);
                });
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        removeBlogs(remove_id)
            .then((res) => {
                setmodal_delete(!modal_delete);
                fetchCategories();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [errBT, setErrBT] = useState(false);
    const [errBD, setErrBD] = useState(false);
    const [errBTD, setErrBTD] = useState(false);
    const [errBI, setErrBI] = useState(false);

    const validate = () => {
        const errors = {};
        if (CountryID === "") {
            errors.CountryID = "Country is required!";
            setErrCN(true);
        }
        if (StateID === "") {
            errors.StateID = "State is required!";
            setErrSN(true);
        }
        if (CityID === "") {
            errors.CityID = "City is required!";
            setErrCiN(true);
        }
        if (companyName === "") {
            errors.companyName = "Client name is required!";
            setErrBT(true);
        }
        if (address === "") {
            errors.address = "address is required!";
            setErrBTD(true);
        }
        if (GST === "") {
            errors.GST = "GST is required!";
            setErrBTD(true);
        }
        if (selectedFile === "") {
            errors.GSTFile = "GST File is required!";
            setErrBTD(true);
        }
        if (address === "") {
            errors.address = "address is required!";
            setErrBTD(true);
        }
        if (GST === "") {
            errors.GST = "GST is required!";
            setErrBTD(true);
        }
        if (companyCategory === "") {
            errors.companyCategory = "Client category is required!";
            setErrBTD(true);
        }
        if (mobileNumber === "") {
            errors.mobileNumber = "mobile number is required!";
            setErrBT(true);
        }

        if (email === "") {
            errors.email = "email is required!";
            setErrBD(true);
        }
        if (password === "") {
            errors.password = "password is required!";
            setErrBI(true);
        }
        return errors;
    };
    const [errSN, setErrSN] = useState(false);
    const validClassStateName =
        errSN && isSubmit ? "form-control is-invalid" : "form-control";

    // const validClassBT =
    //     errBT && isSubmit ? "form-control" : "form-control";

    const validClassBD =
        errBD && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassBTD =
        errBTD && isSubmit ? "form-control is-invalid" : "form-control";

    const validClassBI =
        errBI && isSubmit ? "form-control is-invalid" : "form-control";

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();

    const [photoAdd, setPhotoAdd] = useState("");
    const [checkImagePhoto, setCheckImagePhoto] = useState(false);
    const PhotoUpload = (e) => {
        if (e.target.files.length > 0) {
            const image = new Image();

            let imageurl = URL.createObjectURL(e.target.files[0]);
            console.log("pdf", e.target.files[0]);

            setPhotoAdd(imageurl);
            // setValues({ ...values, blogImage: e.target.files[0] });
            setGSTFile(e.target.files[0]);
            setCheckImagePhoto(true);
        }
    };

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [pageNo, perPage, column, sortDirection, query, filter, filterV]);

    const fetchCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        await axios
            .post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/company`, {
                skip: skip,
                per_page: perPage,
                sorton: column,
                sortdir: sortDirection,
                match: query,
                isVerified: filterV,
                isActive: filter,
            })
            .then((response) => {
                if (response) {
                    // let res = response[0];
                    setLoading(false);
                    // console.log(response.data);
                    setData(response.data);
                    setTotalRows(response.count);
                } else if (response.length === 0) {
                    setData([]);
                }
                // console.log(res);
            })
            .catch((error) => {
                toast.error("Something went wrong. Please try again later");
            })

        setLoading(false);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
    };
    const handleFilter = (e) => {
        setFilter(e.target.checked);
    };
    const handleFilterV = (e) => {
        setFilterV(e.target.checked);
    };

    const handleUpdateCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        setPhotoAdd("");
        setUpdateForm(false);
        setShowForm(false);
        setCheckImagePhoto(false);
        setGSTFile("");
        setcompanyName("");
        setSelectedFile("");
        setaddress("");
        setGST("");
        setcompanyCategory("");
        setmobileNumber("");
        setemail("");
        setpassword("");
        setcertificates([]);
        setisVerified(false);
        setisActive(false);
        setuserId("");
    };

    useEffect(() => {
        // console.log("Address", address);
    }, [address]);

    // const [certificates, setcertificates] = useState([]);
    const validClassBT = ""; // Add your validation class logic here

    const col = [
        {
            name: "Client Name",
            cell: (row) => row.companyName,
            sortable: true,
            sortField: "companyName",
            minWidth: "100px",
        },
        {
            name: "Client Type",
            cell: (row) => row.companyCategory[0].categoryName,
            sortable: true,
            sortField: "companyCategory",
            minWidth: "150px",
        },
        {
            name: "Status",
            selector: (row) => {
                return <p>{row.isActive ? "Active" : "InActive"}</p>;
            },
            sortable: false,
            sortField: "Status",
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <React.Fragment>
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button
                                    className="btn btn-sm btn-success edit-item-btn "
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                    onClick={() => {
                                        handleTog_edit(row);
                                        setRow(row);
                                        console.log(row);
                                    }}
                                >
                                    View
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            },
            sortable: false,
            minWidth: "180px",
        },
    ];

    // file
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileURL, setFileURL] = useState("");

    // Handle file selection
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log(file);
            setFileURL(URL.createObjectURL(file));
        }
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = selectedFile;
        link.setAttribute("download", selectedFile.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingCertificate, setEditingCertificate] = useState(null);


    useEffect(() => {
        // fetchCertificates();
        {
            companyId &&
            getCompanyCertificates(companyId).then((res) => {
                // console.log("Company", res);
                setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
            });
        }
    }, []); // Fetch certificates on initial component load

    // Function to handle opening edit modal
    const handleEditModalOpen = (certificate) => {
        setEditingCertificate(certificate);
        setEditModalOpen(true);
        // fetchCertificates(); // Fetch certificates again to get updated data
        {
            companyId &&
            getCompanyCertificates(companyId).then((res) => {
                // console.log("Company", res);
                setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
            });
        }
    };

    // Function to handle closing edit modal
    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setEditingCertificate(null);
        // fetchCertificates(); // Fetch certificates again to get updated data
        {
            companyId &&
            getCompanyCertificates(companyId).then((res) => {
                // console.log("Company", res);
                setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
            });
        }
    };

    // Function to update certificate after editing
    const handleUpdateCertificate = (updatedCertificate) => {
        const updatedCertificates = certificatesWithPdfUrl.map((cert) => {
            if (cert._id === updatedCertificate._id) {
                return updatedCertificate;
            }
            return cert;
        });
        setCertificatesWithPdfUrl(updatedCertificates);
        // fetchCertificates(); // Fetch certificates again to get updated data
    };

    // Company Category DropDown
    const [selectedOption, setSelectedOption] = useState("");
    const [options, setOptions] = useState([]);

    const [values, setValues] = useState(initialState);
    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            // console.log("no errors");
        }
    }, [formErrors, isSubmit]);

    const [Cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        loadCountries();
    }, []);
    useEffect(() => {
        if (CountryID) {
            loadStates();
        }
    }, [CountryID]);
    useEffect(() => {
        if (StateID) {
            loadCity();
        }
    }, [StateID]);

    const loadCity = () => {
        listCityByState(StateID).then((res) => {
            setCities(res);
            // console.log("res: ", res);
        });
    };

    const loadCountries = () => {
        listActiveCountry().then((res) => setCountries(res));
    };
    // loadCountries();

    const loadStates = () => {
        listStatesByCountry(CountryID).then((res) => {
            setStates(res);
        });
    };

    const handleChangeAddress = (e) => {
        // console.log(e.target.name);
        if (e.target.name === "CountryID") {
            setCountryID(e.target.value);
            // console.log(CountryIDV);
        }
        if (e.target.name === "StateID") {
            setStateID(e.target.value);
            // console.log(StateIDV);
        }
        if (e.target.name === "CityID") {
            setCityID(e.target.value);
            // console.log(CityIDV);
        }
        if (e.target.name === "Address") {
            setaddress(e.target.value);
            // console.log(addressV);
        }
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleCheck = (e) => {
        setValues({...values, IsActive: e.target.checked});
    };

    const [errCiN, setErrCiN] = useState(false);
    const [errCC, setErrCC] = useState(false);
    // const [errSN, setErrSN] = useState(false);
    const [errCN, setErrCN] = useState(false);
    const [errAD, setErrAD] = useState(false);
    const [errLocation, setErrLocation] = useState(false);
    const [errLAT, setErrLAT] = useState(false);
    const [errLONG, setErrLONG] = useState(false);

    const validateAddress = () => {
        const errors = {};
        if (CityID == "") {
            errors.CityID = "Select City required!";
            setErrCiN(true);
        }
        if (CityID !== "") {
            setErrCiN(false);
        }
        if (CountryID == "") {
            errors.CountryID = "Select country name!";
            setErrCN(true);
        }
        if (CountryID !== "") {
            setErrCN(false);
        }
        if (address == "") {
            errors.Address = "Address is Required!";
            setErrAD(true);
        }
        if (address !== "") {
            setErrAD(false);
        }
        if (Location !== "") {
            setErrLocation(false);
        }
        if (StateID == "") {
            errors.StateID = "Select state name!";
            setErrSN(true);
        }
        if (StateID !== "") {
            setErrSN(false);
        }

        return errors;
    };

    const validClassCountryName =
        errCN && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassCityName =
        errCiN && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassAdd =
        errAD && isSubmit ? "form-control is-invalid" : "form-control";

    const validClassLAT =
        errLAT && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassLONG =
        errLONG && isSubmit ? "form-control is-invalid" : "form-control";

    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, []);

    /////////////////////// Fetch Company Categories ///////////////////////

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/list/categories`
                );
                // console.log("Response data:", response);
                setOptions(response);
            } catch (error) {
                console.error("Error fetching certificates:", error);
                toast.error("Error fetching certificates")
            }
        };

        fetchCertificates();
    }, []);

    const handleChange = (e) => {
        setcompanyCategory(e.target.value);
        setSelectedOption(e.target.value);
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, [isOpen]);

    const toggleModalIssueCertificate = () => setIsOpen(!isOpen);

    // Replace with your company ID and certificates data
    // const companyId = 'yourCompanyId
    const [companyId, setCompanyId] = useState("");

    const handleCertificateIssued = (issuedCertificate) => {
        // Handle issued certificate as needed (e.g., update state, show notification)
        console.log("Issued Certificate:", issuedCertificate);
        {
            companyId &&
            getCompanyCertificates(companyId).then((res) => {
                console.log("Company", res);
                setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
            });
        }
    };

    const handleDeleteCertificate = (certificateId) => {
        // Logic to delete the certificate
        console.log("Deleting certificate with ID:", certificateId);
        // Perform your delete operation here, e.g., API call
        deleteCompanyCertificate(certificateId)
            .then((res) => {
                // console.log("Certificate deleted:", res);
                {
                    companyId &&
                    getCompanyCertificates(companyId).then((res) => {
                        console.log("Company", res);
                        setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
                    });
                }
                handleDeleteModalClose();
                toast.success("Certificate deleted successfully");
                setmodal_delete2(false);
            })
    };


    const [modalDelete, setModalDelete] = useState(false);
    const [currentCertificateId, setCurrentCertificateId] = useState(null);

    const handleDeleteModalOpen = (certificateId) => {
        setCurrentCertificateId(certificateId);
        // setModalDelete(true);
        setmodal_delete2(true);
    };

    const handleDeleteModalClose = () => {
        setCurrentCertificateId(null);
        setModalDelete(false);
        setmodal_delete2(false);
    };

    const handleDownloadExcel = async () => {
        try {
            // console.log("Download Excel", row._id);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/sheet/certificatesByCompany/${row._id}`,
                {
                    responseType: 'blob',
                });

            const blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            // Save the file using FileSaver
            saveAs(blob, 'report.xlsx');
        } catch (error) {
            console.error("Error downloading the file:", error);
            toast.error("Error downloading the file")
        }
    }


    document.title = "Client Certificates | Certificate MS";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        maintitle="Client"
                        title="Client Certificates"
                        pageTitle="Client"
                    />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        <Col className="col-sm" lg={4} md={6} sm={6}>
                                            <h2 className="card-title mb-0 fs-4 mt-2">
                                                Client Certificates
                                            </h2>
                                        </Col>
                                        <Col lg={2} md={2} sm={2}>
                                            <div
                                                style={{
                                                    display: showForm || updateForm ? "none" : "",
                                                }}
                                            >
                                                <div className="text-end mt-1">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="filterV"
                                                        value={filterV}
                                                        defaultChecked={true}
                                                        onChange={handleFilterV}
                                                    />
                                                    <Label className="form-check-label ms-2">
                                                        Verified
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={2} sm={2}>
                                            <div
                                                style={{
                                                    display: showForm || updateForm ? "none" : "",
                                                }}
                                            >
                                                <div className="text-end mt-1">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="filter"
                                                        value={filter}
                                                        defaultChecked={true}
                                                        onChange={handleFilter}
                                                    />
                                                    <Label className="form-check-label ms-2">
                                                        Active
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                                            <div className="d-flex justify-content-sm-end">
                                                {/* add btn */}
                                                <div
                                                    style={{
                                                        display: showForm || updateForm ? "none" : "",
                                                    }}
                                                >
                                                    <Row>
                                                        {/* <Col lg={12}>
                              <div className="d-flex justify-content-sm-end">
                                <div>
                                  <Button
                                    color="success"
                                    className="add-btn me-1"
                                    onClick={() => {
                                      setShowForm(!showForm);
                                      setuserId("");
                                      setisActive(false);
                                      setisVerified(false);
                                      setcompanyName("");
                                      setSelectedFile("");
                                      setmobileNumber("");
                                      setemail("");
                                      setcertificates([]);
                                      setpassword("");
                                      setaddress("");
                                      setGST("");
                                      setcompanyCategory("");
                                      setSelectedOption("");
                                      setCityID("");
                                      setStateID("");
                                      setCountryID("");
                                      setaddress("");
                                      console.log(
                                        "Add Button Clicked",
                                        CountryID
                                      );
                                      // setFileId(Math.random() * 100000);
                                    }}
                                    // onClick={() => tog_list()}
                                    // id="create-btn"
                                  >
                                    <i className="ri-add-line align-bottom me-1"></i>
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </Col> */}
                                                    </Row>
                                                </div>

                                                {/* update list btn */}

                                                <div
                                                    style={{
                                                        display: showForm || updateForm ? "" : "none",
                                                    }}
                                                >
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="text-end">
                                                                <button
                                                                    className="btn bg-success text-light mb-3 "
                                                                    onClick={handleDownloadExcel}
                                                                    style={{marginRight: "10px"}}
                                                                >
                                                                    <i className="ri-download-2-fill align-bottom me-1"></i>{" "}
                                                                    Download
                                                                </button>
                                                                <button
                                                                    className="btn bg-success text-light mb-3 "
                                                                    onClick={() => {
                                                                        setuserId("");
                                                                        setisVerified(false);
                                                                        setisActive(false);
                                                                        setUpdateForm(false);
                                                                        setpassword("");
                                                                        setcompanyName("");
                                                                        setSelectedFile("");
                                                                        setmobileNumber("");
                                                                        setemail("");
                                                                        setcertificates([]);
                                                                        setShowForm(false);
                                                                        setSelectedOption("");
                                                                    }}
                                                                >
                                                                    <i className="ri-list-check align-bottom me-1"></i>{" "}
                                                                    List
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* </div> */}
                                                </div>

                                                {/* search */}
                                                <div
                                                    className="search-box ms-2"
                                                    style={{
                                                        display: showForm || updateForm ? "none" : "",
                                                    }}
                                                >
                                                    <input
                                                        className="form-control search"
                                                        placeholder="Search..."
                                                        onChange={(e) => setQuery(e.target.value)}
                                                    />
                                                    <i className="ri-search-line search-icon "></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                {/* UPDATE FORM  */}
                                <div
                                    style={{
                                        display: !showForm && updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"companyName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Client Name"
                                                                                required
                                                                                readOnly
                                                                                name="companyName"
                                                                                value={companyName}
                                                                                onChange={(e) => {
                                                                                    setcompanyName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Client Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.companyName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"GST" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Category "
                                                                                required
                                                                                readOnly
                                                                                name="Category"
                                                                                value={companyCategory[0]?.categoryName ?? ""}
                                                                                onChange={(e) => {
                                                                                    setGST(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Category{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.GST}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>

                                                                    <Row>
                                                                        {certificatesWithPdfUrl.length > 0 ? (
                                                                            certificatesWithPdfUrl.map((certificate) => (
                                                                                <Col lg={6} md={12}
                                                                                     key={certificate._id}
                                                                                     className="mb-3">
                                                                                    <div
                                                                                        className="certificate-item"
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            padding: "15px",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{flex: 1}}>
                                                                                            <Label
                                                                                                style={{
                                                                                                    fontSize: "1.2em",
                                                                                                    marginRight: "10px",
                                                                                                    marginBottom: "0",
                                                                                                    fontWeight: "bold",
                                                                                                }}
                                                                                            >
                                                                                                {certificate.certificateName}
                                                                                            </Label>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Issue
                                                                                                    Date:</strong>{" "}
                                                                                                {new Date(certificate.issueDate).toLocaleDateString()}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Expiration
                                                                                                    Date:</strong>{" "}
                                                                                                {certificate.expireDate === null ? 'Lifetime' : new Date(certificate.expireDate).toLocaleDateString()}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Remarks:</strong>{" "}
                                                                                                {(certificate.remarks)}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Authority
                                                                                                    Name:</strong>{" "}
                                                                                                {(certificate.agent.length > 0 ? certificate.agent[0].name : 'No Authority')}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Authority
                                                                                                    Mobile:</strong>{" "}
                                                                                                {(certificate.agent.length > 0 ? certificate.agent[0].mobileNumber : 'No Authority')}
                                                                                            </p>
                                                                                        </div>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            size="sm"
                                                                                            onClick={() =>
                                                                                                window.open(certificate.certificatePDFUrl, "_blank")
                                                                                            }
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                                marginRight: "10px",
                                                                                            }}
                                                                                        >
                                                                                            View
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="info"
                                                                                            size="sm"
                                                                                            onClick={() => handleEditModalOpen(certificate)}
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                                marginRight: "10px",
                                                                                            }}
                                                                                        >
                                                                                            Edit
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="danger"
                                                                                            size="sm"
                                                                                            onClick={() => handleDeleteModalOpen(certificate._id)}
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            ))
                                                                        ) : (
                                                                            <p>No certificates available.</p>
                                                                        )}
                                                                    </Row>

                                                                    <Modal isOpen={modal_delete2}
                                                                           toggle={handleDeleteModalClose}>
                                                                                            <ModalHeader
                                                            className="bg-light p-3"
                                                            toggle={() => {
                                                                setmodal_delete2(!modal_delete2);
                                                            }}
                                                        >
                                                            <span style={{marginRight: "210px"}}>Remove Certificate</span>
                                                        </ModalHeader>

                                                                        <form>
                                                                            <ModalBody>
                                                                                <div className="mt-2 text-center">
                                                                                    <lord-icon
                                                                                        src="https://cdn.lordicon.com/gsqxdxog.json"
                                                                                        trigger="loop"
                                                                                        colors="primary:#f7b84b,secondary:#f06548"
                                                                                        style={{
                                                                                            width: "100px",
                                                                                            height: "100px"
                                                                                        }}
                                                                                    ></lord-icon>
                                                                                    <div
                                                                                        className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                                                                        <h4>Are you sure ?</h4>
                                                                                        <p className="text-muted mx-4 mb-0">
                                                                                            Are you Sure You want to
                                                                                            Remove this Record ?
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </ModalBody>
                                                                            <ModalFooter>
                                                                                <div
                                                                                    className="hstack gap-2 justify-content-end">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-danger"
                                                                                        id="add-btn"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleDeleteCertificate(currentCertificateId);
                                                                                        }}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-danger"
                                                                                        onClick={handleDeleteModalClose}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </ModalFooter>
                                                                        </form>
                                                                    </Modal>


                                                                    {/* Edit Certificate Modal */}
                                                                    <EditCertificateModal
                                                                        isOpen={editModalOpen}
                                                                        toggleModal={handleEditModalClose}
                                                                        certificate={editingCertificate}
                                                                        onUpdateCertificate={
                                                                            handleUpdateCertificate
                                                                        }
                                                                        companyCategory={companyCategory}
                                                                    />
                                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                                        <Button
                                                                            // color="primary"
                                                                            onClick={toggleModalIssueCertificate}
                                                                            // style={{maxWidth: "10rem"}}
                                                                        >
                                                                            Issue Certificate
                                                                        </Button>
                                                                    </div>
                                                                    <IssueCertificateModal
                                                                        companyCategory={companyCategory}
                                                                        isOpen={isOpen}
                                                                        toggleModal={toggleModalIssueCertificate}
                                                                        companyId={companyId}
                                                                        certificates={certificates}
                                                                        onCertificateIssued={
                                                                            handleCertificateIssued
                                                                        }
                                                                    />


                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}


                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* list */}
                                <div
                                    style={{
                                        display: showForm || updateForm ? "none" : "block",
                                    }}
                                >
                                    <CardBody>
                                        <div>
                                            <div className="table-responsive table-card mt-1 mb-1 text-right">
                                                <DataTable
                                                    columns={col}
                                                    data={data}
                                                    progressPending={loading}
                                                    sortServer
                                                    onSort={(column, sortDirection, sortedRows) => {
                                                        handleSort(column, sortDirection);
                                                    }}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationRowsPerPageOptions={[
                                                        10,
                                                        50,
                                                        100,
                                                        totalRows,
                                                    ]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*Remove Modal*/}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                    setuserId("");
                    setisVerified(false);

                    setcompanyName("");
                    setmobileNumber("");
                    setemail("");
                    setcertificates([]);
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(false)
                    }}
                >
                    <span style={{marginRight: "210px"}}>Remove Client</span>
                </ModalHeader>

                <form>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{width: "100px", height: "100px"}}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-danger"
                                id="add-btn"
                                onClick={handleDelete}
                            >
                                Remove
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => setmodal_delete(false)}
                            >
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer/>
        </React.Fragment>
    );
};

export default CustomerCertificates;
