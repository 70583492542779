import React, {useState, useEffect} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    Row,
} from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import {createCategory, removeCategory, updateCategory, listCategory} from "../../functions/Category/CategoryMaster";
import {toast, ToastContainer} from "react-toastify";

const initialState = {
    categoryName: "",
    // CountryCode: "",
    isActive: false,
};

const CategoryMaster = () => {
    const [values, setValues] = useState(initialState);
    const {
        categoryName,
        //  CountryCode,
        isActive,
    } = values;
    // const [CountryCode, setCountryCode] = useState("");
    // const [CountryName, setCountryName] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [filter, setFilter] = useState(true);
    //validation check
    const [errCN, setErrCN] = useState(false);
    const [errCC, setErrCC] = useState(false);

    const [query, setQuery] = useState("");

    const [_id, set_Id] = useState("");
    const [remove_id, setRemove_id] = useState("");

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log("no errors");
        }
    }, [formErrors, isSubmit]);

    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
        setValues(initialState);
        setIsSubmit(false);
    };

    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
    };

    const [modal_edit, setmodal_edit] = useState(false);
    const handleTog_edit = (row) => {
        setmodal_edit(!modal_edit);
        setIsSubmit(false);
        set_Id(row._id);
        // getCountry(_id)
        //   .then((res) => {
        //     console.log(res);
        setValues({
            ...values,
            // CountryCode: res.CountryCode,
            categoryName: row.categoryName,
            isActive: row.isActive,
        });
        // })
        // .catch((err) => {
        // console.log(err);
        // });
    };

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleCheck = (e) => {
        console.log(e.target.checked);
        setValues({...values, isActive: e.target.checked});
    };

    const handleClick = (e) => {
        e.preventDefault();
        setFormErrors({});
        console.log("category", values);
        let erros = validate(values);
        setFormErrors(erros);
        setIsSubmit(true);

        if (
            // values.CountryCode !== "" &&
            values.categoryName !== ""
        ) {
            createCategory(values)
                .then((res) => {
                    console.log(res.isOk, "pp")
                    if (res.isOk) {
                        console.log(res);
                        console.log(modal_list, "mmm")

                        setmodal_list(!modal_list);
                        setValues(initialState);
                        fetchCategories();
                        toast.success("Category Added Successfully");

                    } else {
                        if (!res.isOk) {
                            console.log("here")
                            setErrCN(true);
                            setFormErrors({
                                categoryName: "Category with this name already exists!",
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setErrCN(true);
                    setFormErrors({
                        categoryName: "Category with this name already exists!",
                    });
                });
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        // console.log("CountryId", remove_id);
        removeCategory(remove_id)
            .then((res) => {
                console.log("deleted", res);
                setmodal_delete(!modal_delete);
                fetchCategories();
                toast.success("Category Removed Successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        console.log("update Category", values);
        let erros = validate(values);
        setFormErrors(erros);
        setIsSubmit(true);

        if (Object.keys(erros).length === 0) {
            updateCategory(_id, values)
                .then((res) => {
                    console.log(res);
                    setmodal_edit(!modal_edit);
                    fetchCategories();
                    toast.success("Category Updated Successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const validate = (values) => {
        const errors = {};

        if (values.categoryName === "") {
            errors.categoryName = "Category Name is required!";
            setErrCN(true);
        }
        if (values.categoryName !== "") {
            setErrCN(false);
        }

        return errors;
    };

    const validClassCategoryName =
        errCN && isSubmit ? "form-control is-invalid" : "form-control";

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [pageNo, perPage, column, sortDirection, query, filter]);

    const fetchCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/list-by-params/category`,
                {
                    skip: skip,
                    per_page: perPage,
                    sorton: column,
                    sortdir: sortDirection,
                    match: query,
                    isActive: filter,
                }
            )
            .then((response) => {
                if (response) {
                    console.log("response is here", response)
                    let res = response;
                    setLoading(false);
                    setCategories(res.data);
                    console.log("sssssss", res.data)
                    setTotalRows(res.total);
                } else if (response.length === 0) {
                    setCategories([]);
                }
                // console.log(res);
            })
            .catch((error) => {
                toast.error("Something went wrong. Please try again later.");
            })

        setLoading(false);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
    };
    const handleFilter = (e) => {
        setFilter(e.target.checked);
    };
    const col = [
        {
            name: "category Name",
            selector: (row) => row.categoryName,
            sortable: true,
            sortField: "categoryName",
            minWidth: "180px",
        },

        {
            name: "Status",
            selector: (row) => {
                return <p>{row.isActive ? "Active" : "InActive"}</p>;
            },
            sortable: false,
            sortField: "Status",
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <React.Fragment>
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button
                                    className="btn btn-sm btn-success edit-item-btn "
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                    onClick={() => handleTog_edit(row)}
                                >
                                    Edit
                                </button>
                            </div>

                            <div className="remove">
                                <button
                                    className="btn btn-sm btn-danger remove-item-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteRecordModal"
                                    onClick={() => tog_delete(row._id)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            },
            sortable: false,
            minWidth: "180px",
        },
    ];

    document.title = "Category Master | Certificate MS";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        maintitle="Category Masters"
                        title="Category Masters"
                        pageTitle="Masters"
                    />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        <Col className="col-sm" lg={4} md={6} sm={6}>
                                            <h2 className="card-title mb-0 fs-4 mt-2">Category</h2>
                                        </Col>
                                        <Col lg={4} md={6} sm={6}>
                                            <div className="text-end mt-2">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="filter"
                                                    value={filter}
                                                    defaultChecked={true}
                                                    onChange={handleFilter}
                                                />
                                                <Label className="form-check-label ms-2">Active</Label>
                                            </div>
                                        </Col>

                                        <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                                            <div className="d-flex justify-content-sm-end">
                                                <div>
                                                    <Button
                                                        color="success"
                                                        className="add-btn me-1"
                                                        onClick={() => tog_list()}
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Add
                                                    </Button>
                                                </div>
                                                <div className="search-box ms-2">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Search..."
                                                        onChange={(e) => setQuery(e.target.value)}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <div className="table-responsive table-card mt-1 mb-1 text-right">
                                            <DataTable
                                                columns={col}
                                                data={categories}
                                                progressPending={loading}
                                                sortServer
                                                onSort={(column, sortDirection, sortedRows) => {
                                                    handleSort(column, sortDirection);
                                                }}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Add Modal */}
            <Modal
                isOpen={modal_list}
                toggle={() => {
                    tog_list();
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_list(false);
                        setIsSubmit(false);
                    }}
                >
                    Add Category
                </ModalHeader>
                <form>
                    <ModalBody>
                        <div className="form-floating mb-3">
                            <Input
                                type="text"
                                className={validClassCategoryName}
                                placeholder="Enter Category Name"
                                required
                                name="categoryName"
                                value={categoryName}
                                onChange={handleChange}
                            />
                            <Label>Category Name <span className="text-danger">*</span> </Label>
                            {isSubmit && (
                                <p className="text-danger">{formErrors.categoryName}</p>
                            )}
                        </div>

                        <div className=" mb-3">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                name="isActive"
                                value={isActive}
                                onChange={handleCheck}
                            />
                            <Label className="form-check-label ms-1">Is Active</Label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-success"
                                id="add-btn"
                                onClick={handleClick}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => {
                                    setmodal_list(false);
                                    setValues(initialState);
                                    setIsSubmit(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal
                isOpen={modal_edit}
                toggle={() => {
                    handleTog_edit();
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_edit(false);
                        setIsSubmit(false);
                    }}
                >
                    Edit Category
                </ModalHeader>
                <form>
                    <ModalBody>
                        <div className="form-floating mb-3">
                            <Input
                                type="text"
                                className={validClassCategoryName}
                                placeholder="Enter Category Name"
                                required
                                name="categoryName"
                                value={categoryName}
                                onChange={handleChange}
                            />
                            <Label>Category <span className="text-danger">*</span> </Label>
                            {isSubmit && (
                                <p className="text-danger">{formErrors.categoryName}</p>
                            )}
                        </div>

                        <div className=" mb-3">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                name="isActive"
                                value={isActive}
                                checked={isActive}
                                onChange={handleCheck}
                            />
                            <Label className="form-check-label ms-1">Is Active</Label>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-success"
                                id="add-btn"
                                onClick={handleUpdate}
                            >
                                Update
                            </button>

                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => {
                                    setmodal_edit(false);
                                    setIsSubmit(false);
                                    setFormErrors({});
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(false);
                    }}
                >
                    Remove Category
                </ModalHeader>
                <form>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{width: "100px", height: "100px"}}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-danger"
                                id="add-btn"
                                onClick={handleDelete}
                            >
                                Remove
                            </button>

                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => setmodal_delete(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CategoryMaster;
