import axios from "axios"

export const addCertificateMaster = async (values) => {
    return await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create/certificateMaster`,
        values
    );
}

export const updateCategoryToCertificate = async (certificateId, values) => {
    return await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update/certificate-master/${certificateId}`,
        values
    );
}


export const deleteCertificateMaster = async (_id) => {
    return await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/auth/delete/certificateMaster/${_id}`
    );
}