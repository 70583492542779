import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import {Select, Tag} from "antd";
import {createAgent, getAgentsForCertificate} from "../../functions/AgentV/Agent";
import {toast} from "react-toastify";
import IssueCertificateModal from "./IssueCertificateModal";


const EditCertificateModal = ({certificate, isOpen, toggleModal, onUpdateCertificate}) => {
    const [formData, setFormData] = useState({
        issueDate: certificate ? (certificate.issueDate ? new Date(certificate.issueDate).toISOString().substr(0, 10) : '') : '',
        expireDate: certificate ? (certificate.expireDate ? new Date(certificate.expireDate).toISOString().substr(0, 10) : '') : new Date(null),
        remarks: certificate ? certificate.remarks : '',
        agent: certificate ? certificate.agent : {},
        certificateId: certificate ? certificate.certificate : '',
        isActive: certificate ? certificate.isActive : true,
    });

    const [newAgent, setNewAgent] = useState({
        name: '',
        email: '',
        mobileNumber: ''
    });

    const [isLifetime, setisLifetime] = useState(false)
    const [createNewAgent, setCreateNewAgent] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [certificateAgents, setcertificateAgents] = useState([])

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (certificate?.expireDate === null) {
            setisLifetime(true)
        } else if (certificate?.expireDate !== null) {
            setisLifetime(false)
        }
    }, [certificate?.expireDate])

    // Update formData when certificate prop changes
    useEffect(() => {
        if (certificate) {
            setFormData({
                issueDate: certificate.issueDate ? new Date(certificate.issueDate).toISOString().substr(0, 10) : '',
                expireDate: certificate.expireDate ? new Date(certificate.expireDate).toISOString().substr(0, 10) : '',
                remarks: certificate.remarks || '',
            });
        }
    }, [certificate]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    useEffect(() => {
        if (certificate && certificate.certificate) {
            console.log("Certificate: ", certificate)
            getAgentsForCertificate(certificate.certificate)
                .then((response) => {
                    const agents = response.data.map(agent => ({
                        value: agent._id,
                        label: agent.name,
                        agentData: agent
                    }));
                    console.log("Agents: ", agents)
                    setcertificateAgents(agents);
                })
                .catch((error) => {
                    console.error('Error fetching agents:', error);
                });
        }
        // console.log(formData.certificateId)
    }, [certificate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newAgentId;
        setLoading(true);
        if (createNewAgent === true) {
            try {
                const values = {
                    "name": newAgent.name,
                    "email": newAgent.email,
                    "mobileNumber": newAgent.mobileNumber,
                    "isActive": true,
                    "isVerified": false,
                    "password": "12345678",
                    "proof": null,
                    "providingCertificates": [formData.certificateId]
                }
                const res = await createAgent(values);
                newAgentId = res.agent._id;
            } catch (e) {
                console.log("Error: ", e)
            }
        } else {
            if (certificate.agent.length > 0)
                newAgentId = certificate.agent[0]._id;
            else {
                newAgentId = null
            }
        }
        try {
            const formDataWithFile = new FormData();
            formDataWithFile.append('issueDate', formData.issueDate);
            formDataWithFile.append('expireDate', formData.expireDate ? formData.expireDate : null);
            formDataWithFile.append('remarks', formData.remarks);
            formDataWithFile.append('agent', newAgentId);
            formDataWithFile.append('certificateId', certificate._id);
            formDataWithFile.append('isActive', certificate.isActive);
            formDataWithFile.append('companyId', certificate.company._id)

            if (file) {
                formDataWithFile.append('certificateFile', file);
            }
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/certificateForCompany/${certificate._id}`, formDataWithFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            certificate.issueDate = formData.issueDate;
            certificate.expireDate = formData.expireDate;
            certificate.remarks = formData.remarks;
            setFormData({
                issueDate: '',
                expireDate: '',
                remarks: '',
                agent: {},
                certificateId: '',
                isActive: true,
            })
            if (file) {
                certificate.certificateFile = file;
            }

            // Handle success response
            console.log('Certificate updated:', response);

            // Pass updated certificate back to parent component
            onUpdateCertificate(response.updatedCertificate);

            toggleModal(); // Close the modal after successful update
        } catch (error) {
            console.error('Error updating certificate:', error);
            toast.error('Error updating certificate. Please try again.')
        } finally {
            setLoading(false);
            toggleModal(); // Close the modal on cancel
        }
    };

    const [errors, setErrors] = useState({ email: false, mobileNumber: false });

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setNewAgent({ ...newAgent, email });
        setErrors({ ...errors, email: !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) });
    };

    const handleMobileNumberChange = (e) => {
        const mobileNumber = e.target.value;
        setNewAgent({ ...newAgent, mobileNumber });
        setErrors({ ...errors, mobileNumber: !mobileNumber.match(/^\d{10}$/) });
    };


    const handleCheckBox = (e) => {
        console.log(e.target.value)
        setCreateNewAgent(!createNewAgent)
        if (createNewAgent) {

            setNewAgent('')
        } else {
            setSelectedAgent(null)
        }
    }
    const handleCheckBoxDefault = (e) => {
        console.log(e.target.value)
        setSelectedAgent(null)
    }


    const handleCancel = () => {
        toggleModal(); // Close the modal on cancel
    };

    // Dropzone configuration
    // const {getRootProps, getInputProps} = useDropzone({
    //     accept: '.pdf, .jpg, .jpeg, .png',
    //     multiple: false,
    //     onDrop: (acceptedFiles) => {
    //         setFile(acceptedFiles[0]);
    //     }
    // });
    const maxSize = 5 * 1024 * 1024
    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles, rejectedFiles) => {
          const file = acceptedFiles[0];
          if (file) {
            if (file.size > maxSize) {
              toast.error("File size exceeds 5 MB. Please select a smaller file.");
              return;
            }
            setFile(file);
          }
        }
      });

    // Ensure certificate exists before rendering
    if (!certificate) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>{certificate.certificateName}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="issueDate">Issue Date <span className='text-danger'>*</span></Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"

                            value={(!isLifetime) ? formData.expireDate : new Date(null)}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={isLifetime} onChange={() => {
                                if (!isLifetime) {
                                    setFormData({...formData, expireDate: null})
                                }

                                setisLifetime(!isLifetime);

                            }}/>{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="certificateFile">Certificate File (should be in pdf, jpg, jpeg, or png format, Max Size: 5mb)</Label>
                        {/* <div></div> */}
                        <div {...getRootProps()}
                             style={{cursor: 'pointer', padding: '10px', border: '1px dashed #ccc'}}>
                            <input {...getInputProps()}/>
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                            )}
                        </div>

                    </FormGroup>
                    <FormGroup>
                        <Label for="agent">Authority</Label>
                        <Select
                            showSearch
                            disabled={createNewAgent}
                            value={selectedAgent}
                            style={{width: '100%'}}
                            placeholder="Search and select Authority"
                            optionFilterProp="children"
                            onChange={(value, option) => setSelectedAgent(option.agentData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {certificateAgents.map(agent => (
                                <Select.Option key={agent.value} value={agent.value} agentData={agent.agentData}>
                                    {agent.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormGroup>
                    {selectedAgent && (
                        <div>
                            <p><strong>Name:</strong> {selectedAgent.name}</p>
                            <p><strong>Mobile:</strong> {selectedAgent.mobileNumber}</p>
                            <p><strong>Email:</strong> {selectedAgent.email}</p>
                        </div>
                    )}
                    {!createNewAgent && certificate.agent[0] && !selectedAgent && (
                        <div>
                            <p><strong>Name:</strong> {certificate.agent[0].name}</p>
                            <p><strong>Mobile:</strong> {certificate.agent[0].mobileNumber}</p>
                            <p><strong>Email:</strong> {certificate.agent[0].email}</p>
                        </div>
                    )}
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={selectedAgent === null && !createNewAgent}
                                   onChange={handleCheckBoxDefault}/>{' '}
                            Default Authority
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={createNewAgent} onChange={handleCheckBox}/>{' '}
                            Can't find? Create new
                        </Label>
                    </FormGroup>
                    {createNewAgent && (
                        <>
                            <FormGroup>
                                <Label for="newAgentName">Authoruty Name <span className='text-danger'>*</span> </Label>
                                <Input
                                    type="text"
                                    name="newAgentName"
                                    id="newAgentName"
                                    value={newAgent.name}
                                    onChange={(e) => setNewAgent({...newAgent, name: e.target.value})}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newAgentEmail">Email <span className='text-danger'>*</span></Label>
                                <Input
                                    type="email"
                                    name="newAgentEmail"
                                    id="newAgentEmail"
                                    value={newAgent.email}
                                    onChange={handleEmailChange}
                                    // onChange={(e) => setNewAgent({...newAgent, email: e.target.value})}
                                    required
                                    invalid={errors.email}
                                />
                                <FormFeedback invalid="true">Invalid email address.</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="newAgentMobileNumber">Mobile Number <span className='text-danger'>*</span></Label>
                                <Input
                                    type="text"
                                    name="newAgentMobileNumber"
                                    id="newAgentMobileNumber"
                                    value={newAgent.mobileNumber}
                                    // onChange={(e) => setNewAgent({...newAgent, mobileNumber: e.target.value})}
                                    onChange={handleMobileNumberChange}
                                    required
                                    invalid={errors.mobileNumber}
                                />
                                <FormFeedback invalid="true">Mobile number must be exactly 10 digits.</FormFeedback>
                            </FormGroup>
                        </>
                    )}

                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={loading}>
                            {loading ? 'Updating...' : 'Update Certificate'}
                        </Button>
                        <Button color="secondary" onClick={handleCancel} disabled={loading}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default EditCertificateModal;
