import React, {useEffect, useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import {createAgent, getAgentsForCertificate} from '../../functions/AgentV/Agent';
import {Select} from 'antd';
import {toast} from "react-toastify";

const IssueCertificateModal = ({
                                   companyCategory,
                                   isOpen,
                                   toggleModal,
                                   companyId,
                                   certificates,
                                   onCertificateIssued
                               }) => {
    const [formData, setFormData] = useState({
        certificateId: '',
        issueDate: '',
        expireDate: '',
        remarks: '',
        agent: {},
        isActive: true
    });

    const [createNewAgent, setCreateNewAgent] = useState(false);
    const [isLifetime, setisLifetime] = useState(false)
    const [newAgent, setNewAgent] = useState({
        name: '',
        email: '',
        mobileNumber: ''
    });

    const [certificateAgents, setcertificateAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const fetchCertificatesByCategory = async () => {
        try {
            console.log(companyCategory, "CTTT");
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/${companyCategory[0]._id}`);
            console.log('Response:', response); // Log the response data to check its structure

            // Ensure the response data is an array and contains valid certificate objects
            if (Array.isArray(response)) {
                const fetchedCertificates = response.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
            } else {
                console.error('Invalid response data format:', response);
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
        }
    };

    useEffect(() => {
        fetchCertificatesByCategory();
    }, [companyCategory]);


    useEffect(() => {
        if (formData.certificateId) {
            console.log(formData.certificateId, "bcc");
            getAgentsForCertificate(formData.certificateId)
                .then((resp) => {
                    console.log(resp)
                    const agents = resp.data.map(agent => ({
                        value: agent._id,
                        label: agent.name,
                        agentData: agent
                    }));
                    setcertificateAgents(agents);
                })
                .catch((error) => {
                    console.error('Error fetching agents:', error);
                });
        }

    }, [formData.certificateId])

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        let newAgentId;
        e.preventDefault();
        setLoading(true);
        console.log(createNewAgent, '---true or false ')
        if (createNewAgent === true) {
            try {
                const values = {
                    "name": newAgent.name,
                    "email": newAgent.email,
                    "mobileNumber": newAgent.mobileNumber,
                    "isActive": true,
                    "isVerified": false,
                    "password": "12345678",
                    "proof": null,
                    "providingCertificates": [formData.certificateId]
                }
                const res = await createAgent(values);
                newAgentId = res.agent._id;

            } catch (e) {
                console.log(e.message)
            }
        } else {
            newAgentId = selectedAgent === null ? null : selectedAgent._id;
        }
        try {
            const formDataWithFile = new FormData();
            formDataWithFile.append('companyId', companyId);
            formDataWithFile.append('certificateId', formData.certificateId);
            formDataWithFile.append('issueDate', formData.issueDate);
            formDataWithFile.append('expireDate', formData.expireDate);
            formDataWithFile.append('remarks', formData.remarks);
            formDataWithFile.append('isActive', formData.isActive);
            formDataWithFile.append('agent', newAgentId);
            formDataWithFile.append('certificateFile', file);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            onCertificateIssued(response.issuedCertificate);
            toggleModal(); // Close the modal after successful issuance
            setFormData({
                certificateId: '',
                issueDate: '',
                expireDate: '',
                remarks: '',
                isActive: true,

            });
            setFile(null);
            setSelectedAgent(null);
            setNewAgent('')
        } catch (error) {
            console.error('Error issuing certificate:', error);
            toast.error('Error issuing certificate')
            // Handle error state
        } finally {
            setLoading(false);
            toggleModal(); // Close the modal after issuancefile
        }
    };
    const handleCheckBox = (e) => {
        console.log(e.target.value)
        setCreateNewAgent(!createNewAgent)
        if (createNewAgent) {

            setNewAgent('')
        } else {
            setSelectedAgent(null)
        }
    }

    const handleCancel = () => {
        setFormData({
            certificateId: '',
            issueDate: '',
            expireDate: '',
            remarks: '',
            isActive: true
        });
        setFile(null);
        setSelectedAgent(null);
        toggleModal(); // Close the modal
    };



    const [errors, setErrors] = useState({ email: false, mobileNumber: false });

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setNewAgent({ ...newAgent, email });
        setErrors({ ...errors, email: !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) });
    };

    const handleMobileNumberChange = (e) => {
        const mobileNumber = e.target.value;
        setNewAgent({ ...newAgent, mobileNumber });
        setErrors({ ...errors, mobileNumber: !mobileNumber.match(/^\d{10}$/) });
    };

    const [error, setError] = useState('');

    const onDrop = (acceptedFiles) => {
        const selectedFile = acceptedFiles[0];
        if (selectedFile.size > 5 * 1024 * 1024) { // 5MB in bytes
            setError('File size should not exceed 5MB.');
            setFile(null);
        } else {
            setError('');
            setFile(selectedFile);
        }
    };
    // const {getRootProps, getInputProps} = useDropzone({
    //     accept: '.pdf, .jpg, .jpeg, .png',
    //     multiple: false,
    //     onDrop
    // });
    const maxSize = 5 * 1024 * 1024
    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles, rejectedFiles) => {
          const file = acceptedFiles[0];
          if (file) {
            if (file.size > maxSize) {
              toast.error("File size exceeds 5 MB. Please select a smaller file.");
              return;
            }
            setFile(file);
          }
        }
      });
    // const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.pdf,.jpg,.jpeg,.png' });

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Issue Certificate</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="certificateId">Certificate <span className='text-danger'>*</span></Label>
                        <Input
                            type="select"
                            name="certificateId"
                            id="certificateId"
                            value={formData.certificateId}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select Certificate</option>
                            {options.map((certificate) => (
                                <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="issueDate">Issue Date <span className='text-danger'>*</span></Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"

                            value={(!isLifetime) ? formData.expireDate : new Date(null)}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={isLifetime} onChange={() => {
                                if (!isLifetime) {
                                    setFormData({...formData, expireDate: null})
                                }

                                setisLifetime(!isLifetime);

                            }}/>{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                    <Label for="certificateFile">Certificate File (should be in pdf, jpg, jpeg, or png format, Max Size: 5mb)</Label>
                        <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
                            <input {...getInputProps()} />
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                                )}
                            </div>
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </FormGroup>
                    <FormGroup>
                        <Label for="agent">Authority</Label>
                        <Select
                            showSearch
                            disabled={createNewAgent}
                            value={selectedAgent}
                            style={{width: '100%'}}
                            placeholder="Search and select Authority"
                            optionFilterProp="children"
                            onChange={(value, option) => setSelectedAgent(option.agentData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {certificateAgents.map(agent => (
                                <Select.Option key={agent.value} value={agent.value} agentData={agent.agentData}>
                                    {agent.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormGroup>
                    {selectedAgent && (
                        <div>
                            <p><strong>Name:</strong> {selectedAgent.name}</p>
                            <p><strong>Mobile:</strong> {selectedAgent.mobileNumber}</p>
                            <p><strong>Email:</strong> {selectedAgent.email}</p>
                        </div>
                    )}
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={createNewAgent} onChange={handleCheckBox}/>{' '}
                            Can't find? Create new
                        </Label>
                    </FormGroup>
                    {createNewAgent && (
                        <>
                            <FormGroup>
                                <Label for="newAgentName">Authority Name<span className='text-danger'> *</span></Label>
                                <Input
                                    type="text"
                                    name="newAgentName"
                                    id="newAgentName"
                                    value={newAgent.name}
                                    onChange={(e) => setNewAgent({...newAgent, name: e.target.value})}
                                    required
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label for="newAgentEmail">Email</Label>
                                <Input
                                    type="email"
                                    name="newAgentEmail"
                                    id="newAgentEmail"
                                    value={newAgent.email}
                                    onChange={(e) => setNewAgent({...newAgent, email: e.target.value})}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newAgentMobileNumber">Mobile Number</Label>
                                <Input
                                    type="text"
                                    name="newAgentMobileNumber"
                                    id="newAgentMobileNumber"
                                    value={newAgent.mobileNumber}
                                    onChange={(e) => setNewAgent({...newAgent, mobileNumber: e.target.value})}
                                    required
                                />
                            </FormGroup> */}
                             <FormGroup>
                <Label for="newAgentEmail">Email<span className='text-danger'> *</span></Label>
                <Input
                    type="email"
                    name="newAgentEmail"
                    id="newAgentEmail"
                    value={newAgent.email}
                    onChange={handleEmailChange}
                    invalid={errors.email}
                    required
                />
                <FormFeedback invalid="true">Invalid email address.</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="newAgentMobileNumber">Mobile Number<span className='text-danger'> *</span></Label>
                <Input
                    type="text"
                    name="newAgentMobileNumber"
                    id="newAgentMobileNumber"
                    value={newAgent.mobileNumber}
                    onChange={handleMobileNumberChange}
                    invalid={errors.mobileNumber}
                    required
                />
                <FormFeedback invalid="true">Mobile number must be exactly 10 digits.</FormFeedback>
            </FormGroup>
                        </>
                    )}


                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!formData.certificateId || loading}>
                            {loading ? 'Issuing...' : 'Issue Certificate'}
                        </Button>
                        <Button color="secondary" onClick={handleCancel} disabled={loading}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default IssueCertificateModal;
