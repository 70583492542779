import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { saveAs } from 'file-saver';
import EditCertificateModal from "./EditCertificateModal";
import IssueCertificateModal from "./IssueCertificateModal";
import { toast, ToastContainer } from "react-toastify";

import {
  listCity,
  listStatesByCountry,
  listCityByState,
  listActiveCountry,
  getAddressById,
} from "../../functions/Location/Location";

import {
  createAddress,
  createCompany,
  deleteCompany,
  deleteCompanyCertificate,
  getCompanyCertificates,
  updateAddress,
  updateCompany,
} from "../../functions/CustomerV/Customer";

const initialState = {
  CityID: "",
  CountryID: "",
  StateID: "",
  Address: "",
};

const CustomerMaster = () => {
  const [companyName, setcompanyName] = useState(""); // 1
  const [mobileNumber, setmobileNumber] = useState(""); // 2
  const [email, setemail] = useState(""); // 3
  const [password, setpassword] = useState(""); // 4
  const [certificates, setcertificates] = useState([]); // 5
  const [isVerified, setisVerified] = useState(false); // 6
  const [isActive, setisActive] = useState(false); // 7
  const [address, setaddress] = useState(""); // 8
  const [country, setcountry] = useState(""); // 9
  const [companyProof, setCompanyProof] = useState(""); // 11
  const [companyCategory, setcompanyCategory] = useState({}); // 12
  const [data, setData] = useState([]);
  const [CityID, setCityID] = useState("");
  const [StateID, setStateID] = useState("");
  const [CountryID, setCountryID] = useState("");
  const [companyDetails, setcompanyDetails] = useState();

  const [loadingOption, setLoadingOption] = useState(false);

  const [userId, setuserId] = useState(localStorage.getItem("AdminUser"));

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(true);
  const [filterV, setFilterV] = useState(true);

  const [showForm, setShowForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);

  const [query, setQuery] = useState("");

  const [_id, set_Id] = useState("");
  const [remove_id, setRemove_id] = useState("");


  const [row, setRow] = useState(null);
  const [certificatesWithPdfUrl, setCertificatesWithPdfUrl] = useState([]);
  const [GSTFile, setGSTFile] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [GST, setGST] = useState("");

  const [modalDelete, setModalDelete] = useState(false);
  const [currentCertificateId, setCurrentCertificateId] = useState(null);
  
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingCertificate, setEditingCertificate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditingCertificate(null);
    // fetchCertificates(); // Fetch certificates again to get updated data
    {
        companyId &&
        getCompanyCertificates(companyId).then((res) => {
            // console.log("Company", res);
            setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
        });
    }
};
const handleCertificateIssued = (issuedCertificate) => {
  // Handle issued certificate as needed (e.g., update state, show notification)
  console.log("Issued Certificate:", issuedCertificate);
  {
      companyId &&
      getCompanyCertificates(companyId).then((res) => {
          console.log("Company", res);
          setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
      });
  }
};
  
  const handleDeleteModalOpen = (certificateId) => {
    setCurrentCertificateId(certificateId);
    // setModalDelete(true);
    setmodal_delete(true);
  };

  const handleDeleteModalClose = () => {
      setCurrentCertificateId(null);
      setmodal_delete(false);
  };
  
  const handleEditModalOpen = (certificate) => {
    setEditingCertificate(certificate);
    setEditModalOpen(true);
    // fetchCertificates(); // Fetch certificates again to get updated data
    {
        companyId &&
        getCompanyCertificates(companyId).then((res) => {
            // console.log("Company", res);
            setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
        });
    }
  };

  
  const handleDeleteCertificate = (certificateId) => {
    // Logic to delete the certificate
    console.log("Deleting certificate with ID:", certificateId);
    // Perform your delete operation here, e.g., API call
    deleteCompanyCertificate(certificateId)
        .then((res) => {
            // console.log("Certificate deleted:", res);
            {
                companyId &&
                getCompanyCertificates(companyId).then((res) => {
                    console.log("Company", res);
                    setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
                });
            }
            handleDeleteModalClose();
            toast.success("Certificate deleted successfully");
        })
};

const handleUpdateCertificate = (updatedCertificate) => {
  const updatedCertificates = certificatesWithPdfUrl.map((cert) => {
      if (cert._id === updatedCertificate._id) {
          return updatedCertificate;
      }
      return cert;
  });
  setCertificatesWithPdfUrl(updatedCertificates);
  // fetchCertificates(); // Fetch certificates again to get updated data
};
const [gotFromBackend, setGotFromBackend] = useState(false);

const toggleModalIssueCertificate = () => setIsOpen(!isOpen);
  
  const [updateFormCerti, setUpdateFormCerti] = useState(false);
  const handleTog_edit_new = (row) => {
      setcompanyDetails(row);
      // setmodal_edit(!modal_edit);
      setUpdateFormCerti(true);
      // console.log("Row", row);
      getAddressById(row.address).then((res) => {
          // console.log("Address", res);
          setCountryID(res.CountryID);
          setStateID(res.StateID);
          setCityID(res.CityID);
          setaddress(res.Address);
      });
      getCompanyCertificates(row._id).then((res) => {
          console.log("Company", res);
          setCertificatesWithPdfUrl(res.certificatesWithPdfUrl);
      });
      setGSTFile(row.GSTPDF);
      setIsSubmit(false);
      set_Id(row._id);
      setCompanyId(row._id);
      setcompanyName(row.companyName);
      setFileURL(row.GSTPDF);
      setSelectedFile(row.GSTPDFUrl);
      setaddress(row.address);
      setGST(row.GST);
      setcompanyCategory(row.companyCategory);
      setmobileNumber(row.mobileNumber);
      setemail(row.email);
      setpassword(row.password);
      setcertificates(row.certificates);
      setisVerified(row.isVerified);
      setisActive(row.isActive);
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = (_id) => {
    setModalDelete(true);
    setRemove_id(_id);
  };

  const [modal_edit, setmodal_edit] = useState(false);
  const handleTog_edit = (row) => {
    setcompanyDetails(row);
    // setmodal_edit(!modal_edit);
    setUpdateForm(true);
    console.log("Row", row);
    getAddressById(row.address).then((res) => {
      console.log("Address", res);
      setCountryID(res[0].CountryID);
      setStateID(res[0].StateID);
      setCityID(res[0].CityID);
      setaddress(res[0].Address);
    });
    setCompanyProof(row.companyProof);
    if(row.companyProof){
      setGotFromBackend(true);
    }
    setIsSubmit(false);
    set_Id(row._id);
    setcompanyName(row.companyName);
    setFileURL(row.companyProof);
    setSelectedFile(row.companyProofUrl);
    setaddress(row.address);
    setcompanyCategory(row.companyCategory);
    setmobileNumber(row.mobileNumber);
    setemail(row.email);
    setpassword(row.password);
    setcertificates(row.certificates);
    setisVerified(row.isVerified);
    setisActive(row.isActive);
    setSelectedOption(row.companyCategory[0]);
    console.log(row.companyCategory[0],"ssss");
  };

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors({});
    let errors = validate(companyCategory);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      setLoadingOption(true);

      const addressData = {
        Address: address,
        CountryID: CountryID,
        StateID: StateID,
        CityID: CityID,
      };

      createAddress(addressData)
        .then((res) => {
          // console.log("Address Created", res);
          const formData = new FormData();
          formData.append("companyName", companyName);
          formData.append("mobileNumber", mobileNumber);
          formData.append("address", res.data._id);
          formData.append("companyProof", selectedFile);
          formData.append("companyCategory", selectedOption);
          formData.append("email", email);
          formData.append("password", password);
          formData.append("certificates", certificates);
          formData.append("isVerified", isVerified);
          formData.append("isActive", isActive);

          console.log(formData);
          createCompany(formData)
            .then((res) => {
              // console.log(res);
              setcompanyName("");
              setSelectedFile("");
              setaddress("");
              setcompanyCategory("");
              setmobileNumber("");
              setemail("");
              setpassword("");
              setcertificates([]);
              setisVerified(false);
              setisActive(false);
              setShowForm(false);
              setCompanyProof("");
              setPhotoAdd("");
              setCheckImagePhoto(false);
              setLoadingOption(false);
              setIsSubmit(false);
              setFormErrors({});
              fetchCategories();
              toast.success("Client Added Successfully");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log("Addrees was not Created", err);
        });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteCompany(remove_id)
      .then((res) => {
        setModalDelete(!modalDelete);
        fetchCategories();
        toast.success("Client Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (e) => {
    console.log(selectedOption,"dsds")
    e.preventDefault();
    let erros = validate();
    setFormErrors(erros);
    setIsSubmit(true);

    if (Object.keys(erros).length === 0) {
      setLoadingOption(true);

      const addressData = {
        Address: address,
        CountryID: CountryID,
        StateID: StateID,
        CityID: CityID,
      };

      updateAddress(companyDetails.address, addressData)
        .then((res) => {
          console.log("_id", companyDetails._id);
          const formData = new FormData();
          formData.append("companyName", companyName);
          formData.append("mobileNumber", mobileNumber);
          formData.append("address", res.data._id);
          // formData.append("companyProof", companyProof) ;
          formData.append("companyCategory", selectedOption._id);
          formData.append("email", email);
          formData.append("password", password);
          formData.append("certificates", certificates);
          formData.append("isVerified", isVerified);
          formData.append("isActive", isActive);
          formData.append("companyProof", selectedFile);

          updateCompany(companyDetails._id, formData)
            .then((res) => {
              setcompanyName("");
              setSelectedFile("");
              setaddress("");
              setcompanyCategory("");
              setmobileNumber("");
              setemail("");
              setpassword("");
              setcertificates([]);
              setisVerified(false);
              setisActive(false);
              setUpdateForm(false);
              setLoadingOption(false);
              setCompanyProof("");
              setCheckImagePhoto(false);
              setFormErrors({});
              fetchCategories();
              toast.success("Client Updated Successfully");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log("Addrees was not Created", err);
        });
    }
  };

  const [errBT, setErrBT] = useState(false);
  const [errBD, setErrBD] = useState(false);
  const [errBTD, setErrBTD] = useState(false);
  const [errBI, setErrBI] = useState(false);

  const validate = (companyCategoryCheck) => {
    const errors = {};
    console.log("companyCategory: ", typeof companyCategoryCheck);
    if(companyCategoryCheck === "" || selectedOption === null) {
      errors.companyCategory = "Select Client Category!";
      errors.category = "Select Client Category!";
      setErrBTD(true);
    }
    if(selectedOption === "" || selectedOption === null) {
      errors.companyCategory = "Select Client Category!";
      errors.category = "Select Client Category!";
      setErrBTD(true);
    }
    // if(selectedFile === "") {
    //   console.log("Selected File", selectedFile);
    //   errors.companyProof = "Client Proof is required!";
    //   setErrBTD(true);
    // }
    if (CountryID === "") {
      errors.CountryID = "Country is required!";
      setErrCN(true);
    }
    if (StateID === "") {
      errors.StateID = "State is required!";
      setErrSN(true);
    }
    if (CityID === "") {
      errors.CityID = "City is required!";
      setErrCiN(true);
    }
    if (companyName === "") {
      errors.companyName = "Client name is required!";
      setErrBT(true);
    }
    if (address === "") {
      errors.address = "address is required!";
      errors.Address = "Address is Required!";
      setErrBTD(true);
    }
    
    // if (selectedFile === "") {
    //   errors.companyProof = "Client Proof is required!";
    //   setErrBTD(true);
    // }
    if (address === "") {
      errors.address = "address is required!";
      setErrBTD(true);
    }
    
    if (companyCategory === "") {
      errors.companyCategory = "Client category is required!";
      errors.category = "Select Client Category!";
      setErrBTD(true);
    }
    if (mobileNumber === "") {
      errors.mobileNumber = "mobile number is required!";
      setErrBT(true);
    }
    if(mobileNumber.length < 10){
      errors.mobileNumber = "Mobile Number must be 10 digits!";
      setErrBT(true);
    }
    if (email === "") {
      errors.email = "email is required!";
      setErrBD(true);
    }
    // check weather the email is valid or not
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
      setErrBD(true);
    }
    if (password === "") {
      errors.password = "password is required!";
      setErrBI(true);
    }
    if(password.length < 6){
      errors.password = "Password must be 6 characters long!";
      setErrBI(true);
    }
    return errors;
  };
  const [errSN, setErrSN] = useState(false);
  const validClassStateName =
    errSN && isSubmit ? "form-control is-invalid" : "form-control";

  // const validClassBT =
  //     errBT && isSubmit ? "form-control" : "form-control";

  const validClassBD =
    errBD && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassBTD =
    errBTD && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassBI =
    errBI && isSubmit ? "form-control is-invalid" : "form-control";

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [column, setcolumn] = useState();
  const [sortDirection, setsortDirection] = useState();

  const [photoAdd, setPhotoAdd] = useState("");
  const [checkImagePhoto, setCheckImagePhoto] = useState(false);
  const PhotoUpload = (e) => {
    if (e.target.files.length > 0) {
      const image = new Image();

      let imageurl = URL.createObjectURL(e.target.files[0]);
      console.log("pdf", e.target.files[0]);

      setPhotoAdd(imageurl);
      // setValues({ ...values, blogImage: e.target.files[0] });
      setCompanyProof(e.target.files[0]);
      setCheckImagePhoto(true);
    }
  };

  const handleSort = (column, sortDirection) => {
    setcolumn(column.sortField);
    setsortDirection(sortDirection);
  };

  useEffect(() => {
    // fetchUsers(1); // fetch page 1 of users
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [pageNo, perPage, column, sortDirection, query, filter, filterV]);

  const fetchCategories = async () => {
    setLoading(true);
    let skip = (pageNo - 1) * perPage;
    if (skip < 0) {
      skip = 0;
    }

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/company`, {
        skip: skip,
        per_page: perPage,
        sorton: column,
        sortdir: sortDirection,
        match: query,
        isVerified: filterV,
        isActive: filter,
      })
      .then((response) => {
        if (response) {
          // let res = response[0];
          setLoading(false);
          console.log(response.data);
          setData(response.data);
          setTotalRows(response.total);
        } else if (response.length === 0) {
          setData([]);
        }
        // console.log(res);
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // setPageNo(page);
    setPerPage(newPerPage);
  };
  const handleFilter = (e) => {
    setFilter(e.target.checked);
  };
  const handleFilterV = (e) => {
    setFilterV(e.target.checked);
  };

  const handleAddCancel = (e) => {
    e.preventDefault();
    setIsSubmit(false);
    // setPhotoAdd("");
    // setCheckImagePhoto(false);
    setShowForm(false);
    setUpdateForm(false);
    setcompanyName("");
    setSelectedFile("");
    setaddress("");
    setCompanyProof("");
    setPhotoAdd("");
    setcompanyCategory("");
    setmobileNumber("");
    setemail("");
    setpassword("");
    setcertificates([]);
    setisVerified(false);
    setisActive(false);
    setSelectedOption("");
  };

  const handleUpdateCancel = (e) => {
    e.preventDefault();
    setIsSubmit(false);
    setPhotoAdd("");
    setUpdateForm(false);
    setShowForm(false);
    setCheckImagePhoto(false);
    setCompanyProof("");
    setcompanyName("");
    setSelectedFile("");
    setaddress("");
    setcompanyCategory("");
    setmobileNumber("");
    setemail("");
    setpassword("");
    setcertificates([]);
    setisVerified(false);
    setisActive(false);
    setuserId("");
  };

  useEffect(() => {
    // console.log("Address", address);
  }, [address]);

  // const [certificates, setcertificates] = useState([]);
  const validClassBT = ""; // Add your validation class logic here

  const col = [
    {
      name: "Client Name",
      cell: (row) => row.companyName,
      sortable: true,
      sortField: "companyName",
      minWidth: "100px",
    },
    {
      name: "Mobile No.",
      cell: (row) => row.mobileNumber,
      sortable: true,
      sortField: "mobileNumber",
      // minWidth: "150px",
    },
    {
      name: "Email",
      cell: (row) => row.email,
      sortable: true,
      sortField: "email",
      minWidth: "150px",
    },
    {
      name: "Client Type",
      cell: (row) => row.companyCategory[0]?.categoryName??'Error',
      sortable: true,
      sortField: "companyCategory",
      minWidth: "150px",
    },

    {
      name: "Verified",
      selector: (row) => {
        return <p>{row.isVerified ? "Verified" : "Not Verified"}</p>;
      },
      sortable: false,
      sortField: "Verified",
    },
    {
      name: "Status",
      selector: (row) => {
        return <p>{row.isActive ? "Active" : "InActive"}</p>;
      },
      sortable: false,
      sortField: "Status",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <React.Fragment>
            <div className="d-flex gap-2">
            <div className="edit">
              <button
                  className="btn btn-sm btn-success edit-item-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => {
                      handleTog_edit_new(row);
                      setRow(row);
                      console.log(row);
                  }}
              >
                  View
              </button>
          </div>

              <div className="edit">
                <button
                  className="btn btn-sm btn-success edit-item-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#showModal"
                  onClick={() => {
                    handleTog_edit(row);
                    console.log(row);
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="remove">
                                <button
                                    className="btn btn-sm btn-danger remove-item-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteRecordModal"
                                    onClick={() => tog_delete(row._id)}
                                >
                                    Remove
                                </button>
                            </div>
            </div>
          </React.Fragment>
        );
      },
      sortable: false,
      minWidth: "200px",
    },
  ];

  // file
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState("");

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if(file.size > 5 * 1024 * 1024){
      toast.error("File size should be less than 5MB");
      setSelectedFile(null);
      return;
    }
    else if (file) {
      setGotFromBackend(false);
      setSelectedFile(file);
      console.log(file);
      setFileURL(URL.createObjectURL(file));
    }
  };

  const handleDownload = () => {
    if(gotFromBackend){
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = selectedFile;
    link.setAttribute("download", selectedFile.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }
    else{
      const link = document.createElement('a');
      link.href = URL.createObjectURL(selectedFile);
      link.download = selectedFile.name;
      link.target = '_blank'; // Open the link in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Clean up the object URL
    }
  };

  // Company Category DropDown
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);

  const [values, setValues] = useState(initialState);
  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  const [Cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    loadCountries();
  }, []);
  useEffect(() => {
    if (CountryID) {
      loadStates();
    }
  }, [CountryID]);
  useEffect(() => {
    if (StateID) {
      loadCity();
    }
  }, [StateID]);

  const loadCity = () => {
    listCityByState(StateID).then((res) => {
      setCities(res);
      // console.log("res: ", res);
    });
  };

  const loadCountries = () => {
    listActiveCountry().then((res) => setCountries(res));
  };
  // loadCountries();

  const loadStates = () => {
    listStatesByCountry(CountryID).then((res) => {
      setStates(res);
    });
  };

  const handleChangeAddress = (e) => {
    // console.log(e.target.name);
    if (e.target.name === "CountryID") {
      setCountryID(e.target.value);
      // console.log(CountryIDV);
    }
    if (e.target.name === "StateID") {
      setStateID(e.target.value);
      // console.log(StateIDV);
    }
    if (e.target.name === "CityID") {
      setCityID(e.target.value);
      // console.log(CityIDV);
    }
    if (e.target.name === "Address") {
      setaddress(e.target.value);
      // console.log(addressV);
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setValues({ ...values, IsActive: e.target.checked });
  };

  const [errCiN, setErrCiN] = useState(false);
  const [errCC, setErrCC] = useState(false);
  // const [errSN, setErrSN] = useState(false);
  const [errCN, setErrCN] = useState(false);
  const [errAD, setErrAD] = useState(false);
  const [errLocation, setErrLocation] = useState(false);
  const [errLAT, setErrLAT] = useState(false);
  const [errLONG, setErrLONG] = useState(false);

  const validateAddress = () => {
    const errors = {};
    if (CityID == "") {
      errors.CityID = "Select City required!";
      setErrCiN(true);
    }
    if (CityID !== "") {
      setErrCiN(false);
    }
    if (CountryID == "") {
      errors.CountryID = "Select country name!";
      setErrCN(true);
    }
    if (CountryID !== "") {
      setErrCN(false);
    }
    if (address == "") {
      errors.Address = "Address is Required!";
      setErrAD(true);
    }
    if (address !== "") {
      setErrAD(false);
    }
    // if (Location !== "") {
    //   setErrLocation(false);
    // }
    if (StateID == "") {
      errors.StateID = "Select state name!";
      setErrSN(true);
    }
    if (StateID !== "") {
      setErrSN(false);
    }


    return errors;
  };

  const validClassCountryName =
    errCN && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassCityName =
    errCiN && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassAdd =
    errAD && isSubmit ? "form-control is-invalid" : "form-control";
  const validCompanyCategory =
    errCC && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassLAT =
    errLAT && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassLONG =
    errLONG && isSubmit ? "form-control is-invalid" : "form-control";

  useEffect(() => {
    // fetchUsers(1); // fetch page 1 of users
  }, []);

  /////////////////////// Fetch Company Categories ///////////////////////

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/list/categories`
        );
        // console.log('Response data:', response);
        setOptions(response);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    setcompanyCategory(e.target.value);
    setSelectedOption(e.target.value);
    // console.log('Selected option:', e.target.value);
    // console.log('Selected option:', companyCategory);
  };


  const handleDownloadExcel = async () => {
    if(updateFormCerti){
      try {
        // console.log("Download Excel", row._id);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/sheet/certificatesByCompany/${row._id}`,
            {
                responseType: 'blob',
            });

        const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        // Save the file using FileSaver
        saveAs(blob, 'report.xlsx');
    } catch (error) {
        console.error("Error downloading the file:", error);
        toast.error("Error downloading the file")
    }
  }
    else{
    try {
      let skip = (pageNo - 1) * perPage;
      if (skip < 0) {
        skip = 0;
      }

      const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/sheet/company`,

          {
            skip: skip,
            per_page: perPage,
            sorton: column,
            sortdir: sortDirection,
            match: query,
            isVerified: filterV,
            isActive: filter,
          },
          {
            responseType: 'blob', // Important to handle binary data
          }
      );
      console.log("excel response",response);

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Save the file using FileSaver
      saveAs(blob, 'report.xlsx');
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }
  };


  document.title = "Client Master | EHS Certificate";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            maintitle="Client"
            title="Client Master"
            pageTitle="Client"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3 align-items-center"> {/* Added align-items-center for vertical alignment */}
                    {/* Client Master */}
                    <Col lg={4} md={6} sm={12}>
                      <h2 className="card-title mb-0 fs-4 mt-2">
                        Client Master
                      </h2>
                    </Col>

                    {/* Verified and Active Checkboxes */}
                    <Col lg={2} md={6} sm={12} className="d-flex justify-content-end mb-2 mb-md-0"> {/* Added mb classes for spacing and responsive layout */}
                      <div className="text-end mt-1" style={{ display: (showForm || updateForm) || updateFormCerti ? "none" : "" }}>
                        <Input
                            type="checkbox"
                            className="form-check-input"
                            name="filterV"
                            value={filterV}
                            defaultChecked={true}
                            onChange={handleFilterV}
                        />
                        <Label className="form-check-label ms-2">
                          Verified
                        </Label>
                      </div>

                      <div className="text-end mt-1 ms-md-2" style={{ display: (showForm || updateForm) || updateFormCerti ? "none" : "" }}>
                        <Input
                            type="checkbox"
                            className="form-check-input"
                            name="filter"
                            value={filter}
                            defaultChecked={true}
                            onChange={handleFilter}
                        />
                        <Label className="form-check-label ms-2">
                          Active
                        </Label>
                      </div>
                    </Col>

                    {/* Buttons and Search Box for Small Screens */}
                    <Col lg={6} md={12} sm={12} className="d-flex flex-column flex-sm-row justify-content-end">
                      {/* Buttons Row */}
                      <divv className="d-flex">
                        {/* Download Button */}
                        <div style={{ display: showForm || updateForm ? "none" : "" }}>
                          <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={handleDownloadExcel}
                          >
                            <i className="ri-download-2-fill align-bottom me-1"></i>{" "}
                            Download
                          </Button>
                        </div>

                        {/* Add Button */}
                        <div style={{ display: (showForm || updateForm) || updateFormCerti ? "none" : "" }}>
                          <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={() => {
                                setShowForm(!showForm);
                                setuserId("");
                                setisActive(false);
                                setisVerified(false);
                                setcompanyName("");
                                setSelectedFile("");
                                setmobileNumber("");
                                setemail("");
                                setcertificates([]);
                                setpassword("");
                                setaddress("");
                                setcompanyCategory("");
                                setSelectedOption("");
                                setCityID("");
                                setStateID("");
                                setCountryID("");
                                setaddress("");
                              }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add
                          </Button>
                        </div>
                      </divv>

                      {/* Search Box Row */}
                      <div className="search-box mt-2 mt-sm-0 ms-2" style={{ display: (showForm || updateForm) || updateFormCerti ? "none" : "" }}>
                        <input
                            style={{ width: "100%" }}
                            className="form-control search"
                            placeholder="Search..."
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                      <button
                        style={{ display: (showForm || updateForm) || updateFormCerti ? "" : "none" }}
                        className="btn bg-success text-light mb-3 "
                        onClick={() => {
                            setuserId("");
                            setisVerified(false);
                            setisActive(false);
                            setUpdateForm(false);
                            setpassword("");
                            setcompanyName("");
                            setSelectedFile("");
                            setmobileNumber("");
                            setemail("");
                            setcertificates([]);
                            setShowForm(false);
                            setUpdateFormCerti(false);
                            setSelectedOption("");
                        }}
                    >
                        <i className="ri-list-check align-bottom me-1"></i>{" "}
                        List
                    </button>
                    </Col>
                  </Row>
                </CardHeader>
                {/* ADD FORM  */}
                <div
                  style={{
                    display: showForm && !updateForm ? "block" : "none",
                  }}
                >
                  <CardBody>
                    <React.Fragment>
                      <Col xxl={12}>
                        <Card className="">
                          {/* <PreviewCardHeader title="Billing Product Form" /> */}
                          <CardBody>
                            <div className="live-preview">
                              <Form>
                                <Row>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"companyName" + _id}
                                        type="text"
                                        // className={validClassStateName}
                                        className={`${formErrors.companyName ? `${validClassStateName}` : ''}`}
                                        placeholder="Enter Client Name"
                                        required
                                        name="companyName"
                                        value={companyName}
                                        onChange={(e) => {
                                          setcompanyName(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Client Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.companyName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"mobileNumber" + _id}
                                        type="number"
                                        // className={validClassStateName}
                                        className={`${formErrors.mobileNumber ? `${validClassStateName}` : ''}`}
                                        placeholder="Enter Mobile Number"
                                        required
                                        name="mobileNumber"
                                        value={mobileNumber}
                                        onChange={(e) => {
                                          setmobileNumber(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Mobile Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.mobileNumber}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"email" + _id}
                                        type="email"
                                        // className={validClassStateName}
                                        className={`${formErrors.email ? `${validClassStateName}` : ''}`}
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                          setemail(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.email}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"password" + _id}
                                        type="text"
                                        // className={validClassStateName}
                                        className={`${formErrors.password ? `${validClassStateName}` : ''}`}
                                        placeholder="Enter Password"
                                        required
                                        name="password"
                                        value={password}
                                        onChange={(e) => {
                                          setpassword(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Password{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.password}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div>
                                      <form>
                                        <Row>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="CountryID"
                                                className={
                                                  validClassCountryName
                                                }
                                                // className={`${formErrors.CountryID ? `${validClassStateName}` : ''}`}
                                                onChange={handleChangeAddress}
                                                value={CountryID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select Country</option>
                                                {countries.map((c) => {
                                                  return (
                                                    <React.Fragment key={c._id}>
                                                      {c.isActive && (
                                                        <option value={c._id}>
                                                          {c.CountryName}
                                                        </option>
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                Country{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.CountryID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="StateID"
                                                className={validClassStateName}
                                                // className={`${formErrors.StateID ? `${validClassStateName}` : ''}`}
                                                onChange={handleChangeAddress}
                                                value={StateID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select State </option>
                                                {states.map((s) => {
                                                  return (
                                                    <React.Fragment key={s._id}>
                                                      {s.isActive &&
                                                        CountryID ===
                                                          s.CountryID && (
                                                          <option value={s._id}>
                                                            {s.StateName}
                                                          </option>
                                                        )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                State{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.StateID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="CityID"
                                                className={validClassCityName}
                                                // className={`${formErrors.CityID ? `${validClassStateName}` : ''}`}
                                                onChange={handleChangeAddress}
                                                value={CityID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select City</option>
                                                {Cities.map((s) => {
                                                  return (
                                                    <React.Fragment key={s._id}>
                                                      {s.isActive &&
                                                        CountryID ===
                                                          s.CountryID &&
                                                        StateID ===
                                                          s.StateID && (
                                                          <option value={s._id}>
                                                            {s.CityName}
                                                          </option>
                                                        )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                City{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.CityID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>

                                        <div className="form-floating mb-3">
                                          <Input
                                            type="text"

                                            // className={validClassBT}
                                            className={`${formErrors.Address ? `${validClassStateName}` : ''}`}
                                            placeholder="Enter address"
                                            name="Address"
                                            value={address}
                                            onChange={handleChangeAddress}
                                          />
                                          <Label>
                                            Address{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          {isSubmit && (
                                            <p className="text-danger">
                                              {formErrors.Address}
                                            </p>
                                          )}
                                        </div>
                                      </form>
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <select
                                        name="category"
                                        value={selectedOption}
                                        className={`${formErrors.category ? `${validClassStateName}` : ''}`}
                                        // className={validClassStateName} // Replace with your validClassStateName
                                        onChange={handleChange}
                                      >
                                        <option value="" disabled>
                                          Select an option
                                        </option>
                                        {options.map((option, index) => (
                                          <option key={index} value={option._id}>
                                            {option.categoryName}
                                          </option>
                                        ))}
                                      </select>
                                      <label>
                                        {" "}
                                        Category{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.category}
                                        </p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <label>
                                      Client Proof (should be in pdf, jpg, jpeg, or png format, max size: 5mb){" "}
                                    </label>
                                    <Input
                                      key={"companyProof" + _id}
                                      // className={validClassBI}
                                      type="file"
                                      name="companyProof"
                                      accept=".pdf, .jpg, .jpeg, .png"
                                      onChange={handleFileChange}
                                    />
                                    {selectedFile && (
                                      <div className="m-2">
                                        <p>
                                          Selected File: {selectedFile.name===null?companyProof:selectedFile.name}
                                        </p>
                                        <Button
                                          color="primary"
                                          onClick={handleDownload}
                                        >
                                          Download File
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                  <div className="mt-5">
                                    <Col lg={6}>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"isVerified_" + _id}
                                          type="checkbox"
                                          name="isVerified"
                                          value={isVerified}
                                          onChange={(e) => {
                                            setisVerified(e.target.checked);
                                          }}
                                          checked={isVerified}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="verifiedCheckBox"
                                        >
                                          Is Verified
                                        </Label>
                                      </div>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"isActive_" + _id}
                                          type="checkbox"
                                          name="isActive"
                                          value={isActive}
                                          onChange={(e) => {
                                            setisActive(e.target.checked);
                                          }}
                                          checked={isActive}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="verifiedCheckBox"
                                        >
                                          Is Active
                                        </Label>
                                      </div>
                                    </Col>
                                  </div>

                                  {loadingOption && (
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <h6 className="p-2">
                                        Wait for a few seconds.This process
                                        might take some time.
                                      </h6>
                                    </div>
                                  )}

                                  <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success  m-1"
                                        id="add-btn"
                                        onClick={handleClick}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger m-1"
                                        onClick={handleAddCancel}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </CardBody>{" "}
                        </Card>
                      </Col>
                    </React.Fragment>
                  </CardBody>
                </div>

                {/* UPDATE FORM  */}
                <div
                  style={{
                    display: !showForm && updateForm ? "block" : "none",
                  }}
                >
                  <CardBody>
                    <React.Fragment>
                      <Col xxl={12}>
                        <Card className="">
                          <CardBody>
                            <div className="live-preview">
                              <Form>
                                <Row>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"companyName" + _id}
                                        type="text"
                                        className={validClassBT}
                                        placeholder="Enter Client Name"
                                        required
                                        name="companyName"
                                        value={companyName}
                                        onChange={(e) => {
                                          setcompanyName(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Client Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.companyName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"mobileNumber" + _id}
                                        type="number"
                                        className={validClassBT}
                                        placeholder="Enter Mobile Number"
                                        required
                                        name="mobileNumber"
                                        value={mobileNumber}
                                        onChange={(e) => {
                                          setmobileNumber(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Mobile Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.mobileNumber}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"email" + _id}
                                        type="email"
                                        className={validClassBT}
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                          setemail(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.email}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <Input
                                        key={"password" + _id}
                                        type="text"
                                        className={validClassBT}
                                        placeholder="Enter Password"
                                        required
                                        name="password"
                                        value={password}
                                        onChange={(e) => {
                                          setpassword(e.target.value);
                                        }}
                                      />
                                      <Label>
                                        Password{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {isSubmit && (
                                        <p className="text-danger">
                                          {formErrors.password}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div>
                                      <form>
                                        <Row>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="CountryID"
                                                className={
                                                  validClassCountryName
                                                }
                                                onChange={handleChangeAddress}
                                                value={CountryID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select Country</option>
                                                {countries.map((c) => {
                                                  return (
                                                    <React.Fragment key={c._id}>
                                                      {c.isActive && (
                                                        <option value={c._id}>
                                                          {c.CountryName}
                                                        </option>
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                Country{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.CountryID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="StateID"
                                                className={validClassStateName}
                                                onChange={handleChangeAddress}
                                                value={StateID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select State </option>
                                                {states.map((s) => {
                                                  return (
                                                    <React.Fragment key={s._id}>
                                                      {s.isActive &&
                                                        CountryID ===
                                                          s.CountryID && (
                                                          <option value={s._id}>
                                                            {s.StateName}
                                                          </option>
                                                        )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                State{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.StateID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                          <Col lg={4}>
                                            <div className="form-floating  mb-3">
                                              <select
                                                name="CityID"
                                                className={validClassCityName}
                                                onChange={handleChangeAddress}
                                                value={CityID}
                                                data-choices
                                                data-choices-sorting="true"
                                              >
                                                <option>Select City</option>
                                                {Cities.map((s) => {
                                                  return (
                                                    <React.Fragment key={s._id}>
                                                      {s.isActive &&
                                                        CountryID ===
                                                          s.CountryID &&
                                                        StateID ===
                                                          s.StateID && (
                                                          <option value={s._id}>
                                                            {s.CityName}
                                                          </option>
                                                        )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </select>
                                              <Label>
                                                City{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              {isSubmit && (
                                                <p className="text-danger">
                                                  {formErrors.CityID}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>

                                        <div className="form-floating mb-3">
                                          <Input
                                            type="text"
                                            className={validClassAdd}
                                            placeholder="Enter address"
                                            name="Address"
                                            value={address}
                                            onChange={handleChangeAddress}
                                          />
                                          <Label>
                                            Address{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </Label>
                                          {isSubmit && (
                                            <p className="text-danger">
                                              {formErrors.Address}
                                            </p>
                                          )}
                                        </div>
                                      </form>
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <div className="form-floating mb-3">
                                      <select
                                        name="category"
                                        value={
                                          selectedOption._id
                                            ? selectedOption._id
                                            : companyCategory
                                        }
                                        className="form-control" // Replace with your validClassStateName
                                        onChange={handleChange}
                                      >
                                        <option value="" disabled>
                                          Select an option
                                        </option>
                                        {options.map((option, index) => (
                                          <option key={index} value={option._id}>
                                            {option.categoryName}
                                          </option>
                                        ))}
                                      </select>
                                      <label>
                                        {" "}
                                        Category{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      {isSubmit && (
                                        <p className="text-danger">{formErrors.selectedOption}</p>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <label>
                                      Client Proof (should be in pdf, jpg, jpeg, or png format, max size: 5mb){" "}
                                      {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <Input
                                      key={"comapnyProof" + _id}
                                      // className={validClassBI}
                                      type="file"
                                      name="companyProof"
                                      accept=".pdf, .jpg, .jpeg, .png"
                                      onChange={handleFileChange}
                                    />
                                    {selectedFile && (
                                      <div className="m-2">
                                        <p>
                                          Selected File: {selectedFile.name}
                                        </p>
                                        <Button
                                          color="primary"
                                          onClick={handleDownload}
                                        >
                                          Download File
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                  <div className="mt-5">
                                    <Col lg={6}>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"IsVerified_" + _id}
                                          type="checkbox"
                                          name="isVerified"
                                          value={isVerified}
                                          onChange={(e) => {
                                            setisVerified(e.target.checked);
                                          }}
                                          checked={isVerified}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="verifiedCheckBox"
                                        >
                                          Is Verified
                                        </Label>
                                      </div>
                                      <div className="form-check mb-2">
                                        <Input
                                          key={"isActive_" + _id}
                                          type="checkbox"
                                          name="isActive"
                                          value={isActive}
                                          onChange={(e) => {
                                            setisActive(e.target.checked);
                                          }}
                                          checked={isActive}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="verifiedCheckBox"
                                        >
                                          Is Active
                                        </Label>
                                      </div>
                                    </Col>
                                  </div>

                                  {loadingOption && (
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <h6 className="p-2">
                                        Wait for a few seconds.This process
                                        might take some time.
                                      </h6>
                                    </div>
                                  )}

                                  <Col lg={12}>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className=" btn btn-success m-1"
                                        id="add-btn"
                                        onClick={handleUpdate}
                                      >
                                        Update
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger m-1"
                                        onClick={handleUpdateCancel}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </React.Fragment>
                  </CardBody>
                </div>
{/* shit starts from here */}
                <div
                                    style={{
                                        display: updateFormCerti ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"companyName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Client Name"
                                                                                required
                                                                                readOnly
                                                                                name="companyName"
                                                                                value={companyName}
                                                                                onChange={(e) => {
                                                                                    setcompanyName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Client Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.companyName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"GST" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Category "
                                                                                required
                                                                                readOnly
                                                                                name="Category"
                                                                                value={companyCategory[0]?.categoryName ?? ""}
                                                                                onChange={(e) => {
                                                                                    setGST(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Category{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.GST}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>

                                                                    <Row>
                                                                        {certificatesWithPdfUrl.length > 0 ? (
                                                                            certificatesWithPdfUrl.map((certificate) => (
                                                                                <Col lg={6} md={12}
                                                                                     key={certificate._id}
                                                                                     className="mb-3">
                                                                                    <div
                                                                                        className="certificate-item"
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            padding: "15px",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{flex: 1}}>
                                                                                            <Label
                                                                                                style={{
                                                                                                    fontSize: "1.2em",
                                                                                                    marginRight: "10px",
                                                                                                    marginBottom: "0",
                                                                                                    fontWeight: "bold",
                                                                                                }}
                                                                                            >
                                                                                                {certificate.certificateName}
                                                                                            </Label>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Issue
                                                                                                    Date:</strong>{" "}
                                                                                                {new Date(certificate.issueDate).toLocaleDateString()}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Expiration
                                                                                                    Date:</strong>{" "}
                                                                                                {certificate.expireDate === null ? 'Lifetime' : new Date(certificate.expireDate).toLocaleDateString()}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Remarks:</strong>{" "}
                                                                                                {(certificate.remarks)}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Authority
                                                                                                    Name:</strong>{" "}
                                                                                                {(certificate.agent.length > 0 ? certificate.agent[0].name : 'No Authority')}
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    marginBottom: "0.5rem",
                                                                                                    fontSize: "0.9em",
                                                                                                }}
                                                                                            >
                                                                                                <strong>Authority
                                                                                                    Mobile:</strong>{" "}
                                                                                                {(certificate.agent.length > 0 ? certificate.agent[0].mobileNumber : 'No Authority')}
                                                                                            </p>
                                                                                        </div>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            size="sm"
                                                                                            onClick={() =>{
                                                                                              if(certificate.certificatePDFUrl){
                                                                                                window.open(certificate.certificatePDFUrl, "_blank")
                                                                                              }
                                                                                              else{
                                                                                                toast.error("No PDF available")
                                                                                              }}
                                                                                            }
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                                marginRight: "10px",
                                                                                            }}
                                                                                        >
                                                                                            View
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="info"
                                                                                            size="sm"
                                                                                            onClick={() => handleEditModalOpen(certificate)}
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                                marginRight: "10px",
                                                                                            }}
                                                                                        >
                                                                                            Edit
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="danger"
                                                                                            size="sm"
                                                                                            onClick={() => handleDeleteModalOpen(certificate._id)}
                                                                                            style={{
                                                                                                padding: "0.25rem 0.5rem",
                                                                                                fontSize: "0.875rem",
                                                                                                lineHeight: "1.5",
                                                                                                borderRadius: "0.2rem",
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            ))
                                                                        ) : (
                                                                            <p>No certificates available.</p>
                                                                        )}
                                                                    </Row>

                                                                    <Modal isOpen={modal_delete}
                                                                           toggle={handleDeleteModalClose}>
                                                                                                                                                                        <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(false);
                    }}
                >
                    <span style={{marginRight: "210px"}}>Remove Certificate</span>
                </ModalHeader>

                                                                        <form>
                                                                            <ModalBody>
                                                                                <div className="mt-2 text-center">
                                                                                    <lord-icon
                                                                                        src="https://cdn.lordicon.com/gsqxdxog.json"
                                                                                        trigger="loop"
                                                                                        colors="primary:#f7b84b,secondary:#f06548"
                                                                                        style={{
                                                                                            width: "100px",
                                                                                            height: "100px"
                                                                                        }}
                                                                                    ></lord-icon>
                                                                                    <div
                                                                                        className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                                                                        <h4>Are you sure ?</h4>
                                                                                        <p className="text-muted mx-4 mb-0">
                                                                                            Are you Sure You want to
                                                                                            Remove this Record ?
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </ModalBody>
                                                                            <ModalFooter>
                                                                                <div
                                                                                    className="hstack gap-2 justify-content-end">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-danger"
                                                                                        id="add-btn"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleDeleteCertificate(currentCertificateId);
                                                                                        }}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-danger"
                                                                                        onClick={handleDeleteModalClose}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </ModalFooter>
                                                                        </form>
                                                                    </Modal>


                                                                    {/* Edit Certificate Modal */}
                                                                    <EditCertificateModal
                                                                        isOpen={editModalOpen}
                                                                        toggleModal={handleEditModalClose}
                                                                        certificate={editingCertificate}
                                                                        onUpdateCertificate={
                                                                            handleUpdateCertificate
                                                                        }
                                                                        companyCategory={companyCategory}
                                                                    />
                                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                                        <Button
                                                                            // color="primary"
                                                                            onClick={toggleModalIssueCertificate}
                                                                            // style={{maxWidth: "10rem"}}
                                                                        >
                                                                            Issue Certificate
                                                                        </Button>
                                                                    </div>
                                                                    <IssueCertificateModal
                                                                        companyCategory={companyCategory}
                                                                        isOpen={isOpen}
                                                                        toggleModal={toggleModalIssueCertificate}
                                                                        companyId={companyId}
                                                                        certificates={certificates}
                                                                        onCertificateIssued={
                                                                            handleCertificateIssued
                                                                        }
                                                                    />


                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}


                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                {/* list */}
                <div
                  style={{
                    display: (showForm || updateForm) || updateFormCerti ? "none" : "block",
                  }}
                >
                  <CardBody>
                    <div>
                      <div className="table-responsive table-card mt-1 mb-1 text-right">
                        <DataTable
                          columns={col}
                          data={data}
                          progressPending={loading}
                          sortServer
                          onSort={(column, sortDirection, sortedRows) => {
                            handleSort(column, sortDirection);
                          }}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          paginationRowsPerPageOptions={[
                            10,
                            50,
                            100,
                            totalRows,
                          ]}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>

      {/*Remove Modal*/}
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          tog_delete();
          setuserId("");
          setisVerified(false);

          setcompanyName("");
          setmobileNumber("");
          setemail("");
          setcertificates([]);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setModalDelete(false);
          }}
        >
          <span style={{ marginRight: "210px" }}>Remove Client</span>
        </ModalHeader>

        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => setModalDelete(false)}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CustomerMaster;
