import axios from "axios";

export const createCategory = async (values) => {
    return await axios.post(
      `${process.env.REACT_APP_BASE_URL}/create/category`,
      values
    );
  };
  
  export const removeCategory = async (_id) => {
    return await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/auth/delete/category/${_id}`
    );
  };
  
  export const listCategory = async () => {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/list/categories`
    );
  };
  
  
  export const updateCategory = async (_id, values) => {
    return await axios.put(
      `${process.env.REACT_APP_BASE_URL}/update/category/${_id}`,
      values
    );
  };
  
  export const getCategory = async (_id) => {
    return null
  };
  