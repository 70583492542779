import React, { Component } from "react";
import { Navigate } from "react-router-dom";

import Country from "../pages/LocationSetUp/Country/Country";
import City from "../pages/LocationSetUp/City/City";
import State from "../pages/LocationSetUp/State/State";
import CompanyLocation from "../pages/LocationSetUp/CompanyLocation";
import Login from "../pages/Authentication/Login";
import UserProfile from "../pages/Authentication/user-profile";
import CompanyDetails from "../pages/Setup/CompanyDetails";
import AdminUser from "../pages/Auth/AdminUser";

import AgentMaster from "../pages/AgentsV/AgentMaster";
import AgentWiseCertificate from "../pages/AgentsV/AgentWiseCertificate";
import Location from "../pages/MastersV/Location";
import CompanyType from "../pages/MastersV/CompanyType";
import AdminCompanyProfile from "../pages/MastersV/AdminCompanyProfile";
import Notification from "../pages/MastersV/Notification";
import TypeOfCertificate from "../pages/MastersV/TypeOfCertificate";
import CustomerMaster from "../pages/CustomersV/CustomerMaster";
import CustomerCertificates from "../pages/CustomersV/CustomerCertificates";
import CategoryMaster from "../pages/MastersV/CategoryMaster";

const authProtectedRoutes = [
  // { path: "/dashboard", component: <DashboardCrm /> },
  { path: "/agent-master", component: <AgentMaster/>},
  { path: "/profile", component: <UserProfile /> },
  { path: "/country", component: <Country /> },
  { path: "/city", component: <City /> },
  { path: "/state", component: <State /> },
  { path: "/location", component: <CompanyLocation /> },
  { path: "/admin-user", component: <AdminUser /> },
  { path: "/company-details", component: <CompanyDetails /> },
  { path: "/agent-wise-certificate", component: <AgentWiseCertificate />},
  { path: "/location-master", component: <Location /> },
  { path: "/company-type", component: <CompanyType /> },
  { path: "/admin-company-profile", component: <AdminCompanyProfile /> },
  { path: "/notification", component: <Notification /> },
  { path: "/type-of-certificate", component: <TypeOfCertificate /> },
  { path: "/customer-master", component: <CustomerMaster /> },
  { path: "/customer-certificates", component: <CustomerCertificates />},
  { path: "/category-master",component:<CategoryMaster/>},


  {
    path: "/",
    exact: true,
    component: <Navigate to="/customer-master" />,
  },
  { path: "*", component: <Navigate to="/customer-master" /> },
];

const publicRoutes = [
  // { path: "/dashboard", component: <DashboardCrm /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
