import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";

const VerticalLayout = (props) => {
  const [locationSetup, setLocationSetup] = useState(false);
  const [masters, setMasters] = useState(false);
  const [agents, setAgents] = useState(false);
  // my code
  // const [masters, setMasters] = useState(false)
  // const [agents, setAgents] = useState(false)
  const [customers, setCustomers] = useState()

  const [product, setProduct] = useState(false);
  const [order, setOrder] = useState(false);
  const [category, setCategory] = useState(false);
  const [subs, setSubs] = useState(false);
  const [inquiry, setInquiry] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [customer, setCustomer] = useState(false);

  const navData = navdata().props.children;
  const path = props.router.location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const ul = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      let itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      let matchingMenuItem = itemsArray.find((x) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (props.layoutType === "vertical") {
      initMenu();
    }
  }, [path, props.layoutType]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        if (
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .classList.add("show");
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items) => {
    let actiItems = items.filter((x) => x.classList.contains("active"));

    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  return (
      <React.Fragment>

        <li className="nav-item">
          <Link
              className="nav-link menu-link"
              to="#"
              data-bs-toggle="collapse"
              onClick={() => {
                setMasters(!masters);
              }}
          >
            <span data-key="t-apps"> Masters </span>
          </Link>

          <Collapse className="menu-dropdown" isOpen={masters}>
            <ul className="nav nav-sm flex-column test">
              {/* <li className="nav-item">
                <Link to="/location" className="nav-link">
                  Location
                </Link>
              </li> */}
              
            <li className="nav-item">
              <Link
                className="nav-link menu-link"
                to="#"
                data-bs-toggle="collapse"
                onClick={() => {
                  setLocationSetup(!locationSetup);
                }}
              >
                <span data-key="t-apps"> Location Setup </span>
              </Link>
              <Collapse
                className="menu-dropdown"
                isOpen={locationSetup}
                //   id="sidebarApps"
              >
                <ul className="nav nav-sm flex-column test">
                  <li className="nav-item">
                    <Link to="/country" className="nav-link">
                      Country
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/state" className="nav-link">
                      State
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/city" className="nav-link">
                      City
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
              {/*<li className="nav-item">*/}
              {/*  <Link to="/company-type" className="nav-link">*/}
              {/*    Category Wise Certificates*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="nav-item">
                <Link to="/admin-company-profile" className="nav-link">
                  Admin Company profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/notification" className="nav-link">
                  Notification
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/category-master" className="nav-link">
                  Category Master
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/type-of-certificate" className="nav-link">
                  Certificate Master
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li className="nav-item">
          <Link
              className="nav-link menu-link"
              to="#"
              data-bs-toggle="collapse"
              onClick={() => {
                setAgents(!agents);
              }}
          >
            <span data-key="t-apps"> Authorities </span>
          </Link>
          <Collapse
              className="menu-dropdown"
              isOpen={agents}
          >
            <ul className="nav nav-sm flex-column test">
              <li className="nav-item">
                <Link className="nav-link menu-link" to="/agent-master">
                  <span data-key="t-apps">Authority Master</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link menu-link" to="/agent-wise-certificate">
                  <span data-key="t-apps">Authority Wise Certifications</span>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li className="nav-item">
          <Link
              className="nav-link menu-link"
              to="#"
              data-bs-toggle="collapse"
              onClick={() => {
                setCustomer(!customer);
              }}
          >
            <span data-key="t-apps"> Clients </span>
          </Link>
          <Collapse
              className="menu-dropdown"
              isOpen={customer}
          >
            <ul className="nav nav-sm flex-column test">
              <li className="nav-item">
                <Link className="nav-link menu-link" to="/customer-master">
                  <span data-key="t-apps">Client Master</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link menu-link" to="/customer-certificates">
                  <span data-key="t-apps">Client Certificates</span>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

      </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
