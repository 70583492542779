import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select';

import {
    createBlogs,
    getBlogs,
    removeBlogs,
    updateBlogs,
    uploadImage,
} from "../../functions/Blogs/Blogs";

import { //
    createAgent,
    updateAgent,
} from "../../functions/AgentV/Agent";
import {ToastContainer, toast} from "react-toastify";
// import SearchableDropDown from "./SearchableDropDown";

const AgentMaster = () => {
    const [blogTitle, setblogTitle] = useState("");


    const [agentName, setagentName] = useState("");
    const [mobileNumber, setmobileNumber] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [providingCertificates, setprovidingCertificates] = useState([])
    const [isVerified, setisVerified] = useState(false);
    const [isActive, setisActive] = useState(false);
    const [data, setData] = useState([]);

    const [loadingOption, setLoadingOption] = useState(false);

    const [likes, setlikes] = useState([]);
    const [comments, setcomments] = useState([]);
    const [userId, setuserId] = useState(localStorage.getItem("AdminUser"));

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [filter, setFilter] = useState(true);
    const [filterV, setFilterV] = useState(true);

    const [showForm, setShowForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);

    const [query, setQuery] = useState("");

    const [_id, set_Id] = useState("");
    const [remove_id, setRemove_id] = useState("");

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log("no errors");
        }
    }, [formErrors, isSubmit]);


    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = (_id) => {
        setmodal_delete(!modal_delete);
        setRemove_id(_id);
    };

    const [modal_edit, setmodal_edit] = useState(false);
    const handleTog_edit = (row) => {
        // setmodal_edit(!modal_edit);
        setIsSubmit(false);
        setUpdateForm(true);

        setIsSubmit(false);
        set_Id(row._id);
        setagentName(row.name);
        setmobileNumber(row.mobileNumber);
        setemail(row.email);
        setpassword(row.password);
        setprovidingCertificates(row.providingCertificates);
        setisVerified(row.isVerified);
        setisActive(row.isActive);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setFormErrors({});
        let errors = validate();
        setFormErrors(errors);
        setIsSubmit(true);
    
        if (Object.keys(errors).length === 0) {
            setLoadingOption(true);
    
            const formData = {
                name: agentName,
                mobileNumber: mobileNumber,
                email: email,
                password: password,
                providingCertificates: providingCertificates,
                isVerified: isVerified,
                isActive: isActive
            };
    
            createAgent(formData)
                .then((res) => {
                    // console.log(res);
                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setpassword("");
                    setprovidingCertificates([]);
                    setisVerified(false);
    
                    setShowForm(false);
                    setLoadingOption(false);
                    setIsSubmit(false);
                    setFormErrors({});
                    fetchCategories();
                    toast.success("Agent Added Successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        removeBlogs(remove_id)
            .then((res) => {
                setmodal_delete(!modal_delete);
                fetchCategories();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        let erros = validate();
        setFormErrors(erros);
        setIsSubmit(true);

        if (Object.keys(erros).length === 0) {
            setLoadingOption(true);

            const formData = {
                name: agentName,
                mobileNumber: mobileNumber,
                email: email,
                password: password,
                providingCertificates: providingCertificates,
                isVerified: isVerified,
                isActive: isActive
            }

            updateAgent(_id, formData)
                .then((res) => {
                    // setmodal_edit(!modal_edit);
                    // setPhotoAdd("");
                    setUpdateForm(false);
                    setLoadingOption(false);

                    // setCheckImagePhoto(false);
                    setuserId("");
                    // setViews(0);
                    // setblogImage("");

                    setisVerified(false);
                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setpassword("");
                    setprovidingCertificates([]);
                    fetchCategories();
                    toast.success("Agent Updated Successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const [errBT, setErrBT] = useState(false);
    const [errBD, setErrBD] = useState(false);
    const [errBTD, setErrBTD] = useState(false);
    const [errBI, setErrBI] = useState(false);

    const validate = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (agentName === "") {
            errors.agentName = "Authority name is required!";
            setErrBT(true);
        }
        if (mobileNumber === "" || mobileNumber.length < 10) {
            errors.mobileNumber = "mobile number is valid!";
            setErrBT(true);
        }

        if (email === "" || !emailRegex.test(email)) {
            errors.email = "valid email is required!";
            setErrBD(true);
        }
        if (password === "") {
            errors.password = "password is required!";
            setErrBI(true);
        }
        // if (providingCertificates !== "") {
        //     setErrBD(false);
        // }
        return errors;
    };
    const [errSN, setErrSN] = useState(false);
    const validClassStateName =
        errSN && isSubmit ? "form-control is-invalid" : "form-control";

    // const validClassBT =
    //     errBT && isSubmit ? "form-control" : "form-control";

    const validClassBD =
        errBD && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassBTD =
        errBTD && isSubmit ? "form-control is-invalid" : "form-control";

    const validClassBI =
        errBI && isSubmit ? "form-control is-invalid" : "form-control";

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [pageNo, perPage, column, sortDirection, query, filter, filterV]);

    const fetchCategories = async () => {
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) {
            skip = 0;
        }

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/auth/list-by-params/agents`,
                {
                    skip: skip,
                    per_page: perPage,
                    sorton: column,
                    sortdir: sortDirection,
                    match: query,
                    isVerified: filterV,
                    isActive: filter,
                }
            )
            .then((response) => {
                if (response) {
                    // let res = response[0];
                    setLoading(false);
                    // console.log(response.data);
                    setData(response.data);
                    setTotalRows(response.count);
                } else if (response.length === 0) {
                    setData([]);
                }
                // console.log(res);
            })
            .catch((error) => {
                toast.error("Something went wrong");
            })

        setLoading(false);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        // setPageNo(page);
        setPerPage(newPerPage);
    };
    const handleFilter = (e) => {
        setFilter(e.target.checked);
    };
    const handleFilterV = (e) => {
        setFilterV(e.target.checked);
    };

    const handleAddCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        // setCheckImagePhoto(false);
        setShowForm(false);
        setUpdateForm(false);
        setagentName("");
        setmobileNumber("");
        setemail("");
        setpassword("");
        setprovidingCertificates([]);
        setisVerified(false);
    };

    const handleUpdateCancel = (e) => {
        e.preventDefault();
        setIsSubmit(false);
        // setPhotoAdd("");
        setUpdateForm(false);
        setShowForm(false);
        // setCheckImagePhoto(false);
        setagentName("");
        setmobileNumber("");
        setemail("");
        setpassword("");
        setprovidingCertificates([]);
        setisVerified(false);
        setuserId("");
    };

    // const [providingCertificates, setProvidingCertificates] = useState([]);
    const validClassBT = ""; // Add your validation class logic here

    const col = [
        {
            name: "Name",
            cell: (row) => row.name,
            sortable: true,
            sortField: "agentName",
            minWidth: "150px",
        },
        {
          name: "Providing Certificates",
          cell: (row) => (
            <div style={{ maxWidth: "200px", overflowX: "auto" }}>
              {row.providingCertificates.length > 0 && (
                row.providingCertificate.map((certificate) => (
                  <div key={certificate._id}>{certificate.certificateName}</div>
                ))
              )}
            </div>
          ),
          sortable: true,
          sortField: "providingCertificates",
          minWidth: "150px",
      },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <React.Fragment>
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button
                                    className="btn btn-sm btn-success edit-item-btn "
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                    onClick={() => {
                                        handleTog_edit(row)
                                        console.log(row)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>

                            {/* <div className="remove">
                                <button
                                    className="btn btn-sm btn-danger remove-item-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteRecordModal"
                                    onClick={() => tog_delete(row._id)}
                                >
                                    Remove
                                </button>
                            </div> */}
                        </div>
                    </React.Fragment>
                );
            },
            sortable: false,
            minWidth: "180px",
        },
    ];

    // SearchableDropDown
    const [options, setOptions] = useState([]);

    useEffect(() => {

        const fetchCertificates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
                console.log('Response data:', response); 
                const fetchedCertificates = response.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
                // console.log('fetchedCertificates:', fetchedCertificates);
            } catch (error) {
                console.error('Error fetching certificates:', error);
                toast.error("Error fetching certificates")
            }
        };
    
        fetchCertificates();
    }, []);
    

    const handleChange = (selected) => {
        setprovidingCertificates(selected ? selected.map(option => option.value) : []);
    };

    document.title = "Authority Wise Certificates | Certificate MS";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb maintitle="Authorities" title="Authority Wise Certificates" pageTitle="Authorities" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        <Col className="col-sm" lg={4} md={6} sm={6}>
                                            <h2 className="card-title mb-0 fs-4 mt-2">Authorities Master</h2>
                                        </Col>
                                        <Col lg={2} md={2} sm={2}>
                                            <div
                                                style={{
                                                    display: showForm || updateForm ? "none" : "",
                                                }}
                                            >
                                                <div className="text-end mt-1">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="filterV"
                                                        value={filterV}
                                                        defaultChecked={true}
                                                        onChange={handleFilterV}
                                                    />
                                                    <Label className="form-check-label ms-2">
                                                        Verified
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={2} sm={2}>
                                            <div
                                                style={{
                                                    display: showForm || updateForm ? "none" : "",
                                                }}
                                            >
                                                <div className="text-end mt-1">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="filter"
                                                        value={filter}
                                                        defaultChecked={true}
                                                        onChange={handleFilter}
                                                    />
                                                    <Label className="form-check-label ms-2">
                                                        Active
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="col-sm-auto" lg={4} md={12} sm={12}>
                                            <div className="d-flex justify-content-sm-end">
                                                {/* add btn */}
                                                {/* <div
                                                    style={{
                                                        display: showForm || updateForm ? "none" : "",
                                                    }}
                                                >
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div>
                                                                    <Button
                                                                        color="success"
                                                                        className="add-btn me-1"
                                                                        onClick={() => {
                                                                            setShowForm(!showForm);
                                                                            setlikes([]);
                                                                            setcomments([]);
                                                                            setuserId("");

                                                                            setisVerified(false);
                                                                            setagentName("");
                                                                            setmobileNumber("");
                                                                            setemail("");
                                                                            setprovidingCertificates([]);
                                                                            setpassword("");
                                                                            // setFileId(Math.random() * 100000);
                                                                        }}
                                                                        // onClick={() => tog_list()}
                                                                        // id="create-btn"
                                                                    >
                                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div> */}

                                                {/* update list btn */}

                                                <div
                                                    style={{
                                                        display: showForm || updateForm ? "" : "none",
                                                    }}
                                                >
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="text-end">
                                                                <button
                                                                    className="btn bg-success text-light mb-3 "
                                                                    onClick={() => {
                                                                        // setValues(initialState);
                                                                        setblogTitle("");
                                                                        setlikes([]);
                                                                        setcomments([]);
                                                                        setuserId("");
                                                                        setisVerified(false);
                                                                        setShowForm(false);
                                                                        setUpdateForm(false);
                                                                        setpassword("");
                                                                        setagentName("");
                                                                        setmobileNumber("");
                                                                        setemail("");
                                                                        setprovidingCertificates([]);
                                                                    }}
                                                                >
                                                                    <i className="ri-list-check align-bottom me-1"></i>{" "}
                                                                    List
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* </div> */}
                                                </div>

                                                {/* search */}
                                                <div
                                                    className="search-box ms-2"
                                                    style={{
                                                        display: showForm || updateForm ? "none" : "",
                                                    }}
                                                >
                                                    <input
                                                        className="form-control search"
                                                        placeholder="Search..."
                                                        onChange={(e) => setQuery(e.target.value)}
                                                    />
                                                    <i className="ri-search-line search-icon "></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                {/* ADD FORM  */}
                                <div
                                    style={{
                                        display: showForm && !updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    {/* <PreviewCardHeader title="Billing Product Form" /> */}
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"agentName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Authority Name"
                                                                                required
                                                                                name="agentName"
                                                                                value={agentName}
                                                                                onChange={(e) => {
                                                                                    setagentName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Authority Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.agentName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"mobileNumber" + _id}
                                                                                type="number"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Mobile Number"
                                                                                required
                                                                                name="mobileNumber"
                                                                                value={mobileNumber}
                                                                                onChange={(e) => {
                                                                                    setmobileNumber(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Mobile Number{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.mobileNumber}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"email" + _id}
                                                                                type="email"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Email"
                                                                                required
                                                                                name="email"
                                                                                value={email}
                                                                                onChange={(e) => {
                                                                                    setemail(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Email{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.email}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"password" + _id}
                                                                                type="password"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Password"
                                                                                required
                                                                                name="password"
                                                                                value={password}
                                                                                onChange={(e) => {
                                                                                    setpassword(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Password{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.password}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
    <Col lg={6}>
        <div>
            <h5>Certificates:</h5>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => providingCertificates.includes(option.value))}
                onChange={handleChange}
            />
            <div>
                <ul>
                    {providingCertificates.map(id => {
                        const certificate = options.find(option => option.value === id);
                        return <li key={id}>{certificate ? certificate.label : id}</li>;
                    })}
                </ul>
            </div>
        </div>
    </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsVerified_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isVerified"
                                                                                    value={isVerified}
                                                                                    onChange={(e) => {
                                                                                        setisVerified(e.target.checked);
                                                                                    }}
                                                                                    checked={isVerified}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Verified
                                                                                </Label>
                                                                            </div>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsActive" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>

                                                                        </Col>
                                                                    </div>

                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                                                                <span className="sr-only">
                                                                                  Loading...
                                                                                </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div className="hstack gap-2 justify-content-end">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-success  m-1"
                                                                                id="add-btn"
                                                                                onClick={handleClick}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleAddCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>{" "}
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* UPDATE FORM  */}
                                <div
                                    style={{
                                        display: !showForm && updateForm ? "block" : "none",
                                    }}
                                >
                                    <CardBody>
                                        <React.Fragment>
                                            <Col xxl={12}>
                                                <Card className="">
                                                    <CardBody>
                                                        <div className="live-preview">
                                                            <Form>
                                                                <Row>
                                                                    
                                                                <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"agentName" + _id}
                                                                                type="text"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Authority Name"
                                                                                // required
                                                                                disabled
                                                                                name="agentName"
                                                                                value={agentName}
                                                                                onChange={(e) => {
                                                                                    setagentName(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Authority Name{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.agentName}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <Input
                                                                                key={"mobileNumber" + _id}
                                                                                type="number"
                                                                                className={validClassBT}
                                                                                placeholder="Enter Mobile Number"
                                                                                // required
                                                                                disabled
                                                                                name="mobileNumber"
                                                                                value={mobileNumber}
                                                                                onChange={(e) => {
                                                                                    setmobileNumber(e.target.value);
                                                                                }}
                                                                            />
                                                                            <Label>
                                                                                Mobile Number{" "}
                                                                                <span className="text-danger">*</span>
                                                                            </Label>
                                                                            {isSubmit && (
                                                                                <p className="text-danger">
                                                                                    {formErrors.mobileNumber}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </Col>

    <Col lg={6}>
        <div>
            <h3>Selected Certificates:</h3>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => providingCertificates.includes(option.value))}
                onChange={handleChange}
            />
            <div>
                <ul>
                    {providingCertificates.map(id => {
                        const certificate = options.find(option => option.value === id);
                        return <li key={id}>{certificate ? certificate.label : id}</li>;
                    })}
                </ul>
            </div>
        </div>
    </Col>

                                                                    <div className="mt-5">
                                                                        <Col lg={6}>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsVerified_" + _id}
                                                                                    type="checkbox"
                                                                                    name="isVerified"
                                                                                    value={isVerified}
                                                                                    onChange={(e) => {
                                                                                        setisVerified(e.target.checked);
                                                                                    }}
                                                                                    checked={isVerified}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Verified
                                                                                </Label>
                                                                            </div>
                                                                            <div className="form-check mb-2">
                                                                                <Input
                                                                                    key={"IsActive" + _id}
                                                                                    type="checkbox"
                                                                                    name="isActive"
                                                                                    value={isActive}
                                                                                    onChange={(e) => {
                                                                                        setisActive(e.target.checked);
                                                                                    }}
                                                                                    checked={isActive}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor="verifiedCheckBox"
                                                                                >
                                                                                    Is Active
                                                                                </Label>
                                                                            </div>

                                                                        </Col>
                                                                    </div>

                                                                    {loadingOption && (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div
                                                                                className="spinner-border"
                                                                                role="status"
                                                                            >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                                                            </div>
                                                                            <h6 className="p-2">
                                                                                Wait for a few seconds.This process
                                                                                might take some time.
                                                                            </h6>
                                                                        </div>
                                                                    )}

                                                                    <Col lg={12}>
                                                                        <div className="text-end">
                                                                            <button
                                                                                type="submit"
                                                                                className=" btn btn-success m-1"
                                                                                id="add-btn"
                                                                                onClick={handleUpdate}
                                                                            >
                                                                                Update
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger m-1"
                                                                                onClick={handleUpdateCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    </CardBody>
                                </div>

                                {/* list */}
                                <div
                                    style={{
                                        display: showForm || updateForm ? "none" : "block",
                                    }}
                                >
                                    <CardBody>
                                        <div>
                                            <div className="table-responsive table-card mt-1 mb-1 text-right">
                                                <DataTable
                                                    columns={col}
                                                    data={data}
                                                    progressPending={loading}
                                                    sortServer
                                                    onSort={(column, sortDirection, sortedRows) => {
                                                        handleSort(column, sortDirection);
                                                    }}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationRowsPerPageOptions={[
                                                        10,
                                                        50,
                                                        100,
                                                        totalRows,
                                                    ]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*Remove Modal*/}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    tog_delete();
                    // setValues([]);
                    setlikes([]);
                    setcomments([]);
                    setuserId("");
                    setisVerified(false);

                    setagentName("");
                    setmobileNumber("");
                    setemail("");
                    setprovidingCertificates([]);
                }}
                centered
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                        setmodal_delete(!modal_delete);
                    }}
                >
                    <span style={{ marginRight: "210px" }}>Remove Authority</span>
                </ModalHeader>

                <form>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <lord-icon
                                src="https://cdn.lordicon.com/gsqxdxog.json"
                                trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548"
                                style={{ width: "100px", height: "100px" }}
                            ></lord-icon>
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you sure ?</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you Sure You want to Remove this Record ?
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-danger"
                                id="add-btn"
                                onClick={handleDelete}
                            >
                                Remove
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => setmodal_delete(false)}
                            >
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer />
        </React.Fragment>
    );
};

export default AgentMaster;
